import { Component, OnInit } from '@angular/core';
import { createPopper } from '@popperjs/core';
import { TutorialStep } from '../shared/models';
import { UtilitiesService } from '../shared/services';
import { TutorialService } from '../shared/services/tutorial.service';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent implements OnInit {

  isMobile: boolean;

  actualStep: TutorialStep;
  totalSteps: number;
  tutorialSteps: {[key: number]: TutorialStep} = {
    1: {stepNum: 1, img: '/assets/img/tutorial-desktop/1.png', language: "Start by selecting the total number of seats to purchase.", arrowPosition: 'top'},
    2: {stepNum: 2, img: '/assets/img/tutorial-desktop/2.png', language: "Use the slider to set your desired price range per ticket.", arrowPosition: 'top'},
    3: {stepNum: 3, img: '/assets/img/tutorial-desktop/3.png', language: "If necessary, select the number of wheelchair accessible seats you need. *Note that this number will be included in the total number of seats selected in step 1.", arrowPosition: 'top'},
    4: {stepNum: 4, img: '/assets/img/tutorial-desktop/4.png', language: "Use the seat map to select the section(s) you want to sit in. Selected sections appear in purple.", arrowPosition: 'right'},
    5: {stepNum: 5, img: '/assets/img/tutorial-desktop/5.png', language: "Hover over a section to preview a view from that section. Click “Open Seat Map” to manually select seats in that section.", arrowPosition: 'right'},
    // 6: {stepNum: 6, img: '/assets/img/tutorial-desktop/click-drag.png', language: "Selected seats appear red with a checkmark. If need, change your selection by clicking each seat individual or hold Ctrl + click and drag to select a group of seats", arrowPosition: 'right'},
    6: {stepNum: 6, img: '/assets/img/tutorial-desktop/7.png', language: "Selected seats appear red with a checkmark. If needed, change your selection by clicking each seat individually or hold Ctrl + click and drag to select a group of seats.", arrowPosition: 'right'},
    7: {stepNum: 7, img: '/assets/img/tutorial-desktop/8.png', language: "Selected seats appear in the Cart. Click the map icon to go to that section in the map. Click the trash can to remove seats and make a different selection.", arrowPosition: 'left'},
    8: {stepNum: 8, img: '/assets/img/tutorial-desktop/9.png', language: "When you are ready to purchase, click “Go to Checkout.”", arrowPosition: 'left'},
    9: {stepNum: 9, img: '/assets/img/tutorial-desktop/10.png', language: "Review  your ticket information.", arrowPosition: 'left'},
    10: {stepNum: 10, img: '/assets/img/tutorial-desktop/11.png', language: "Enhance your group outing with an optional upgraded ticket.", arrowPosition: 'right'},
    11: {stepNum: 11, img: '/assets/img/tutorial-desktop/12.png', language: "Protect your group outing with Rainout Insurance. Learn more by clicking the +Info button.", arrowPosition: 'bot'},
    12: {stepNum: 12, img: '/assets/img/tutorial-desktop/13.png', language: "Review the details of your group ticket pricing. Click “Proceed to Payment” to complete your purchase.", arrowPosition: 'bot'},
  };
  tutorialStepsMobile: {[key: number]: TutorialStep} = {
    1: {stepNum: 1, img: '/assets/img/tutorial-mobile/1.png', language: "Start by selecting the total number of seats to purchase.", arrowPosition: 'bot'},
    2: {stepNum: 2, img: '/assets/img/tutorial-mobile/2.png', language: "Use the slider to set your desired price range per ticket.", arrowPosition: 'bot'},
    3: {stepNum: 3, img: '/assets/img/tutorial-mobile/3.png', language: "If necessary, select the number of wheelchair accessible seats you need. *Note that this number will be included in the total number of seats selected in step 1.", arrowPosition: 'bot'},
    4: {stepNum: 4, img: '/assets/img/tutorial-mobile/4.png', language: "Use the seat map to select the section(s) you want to sit in. Selected sections appear in purple.", arrowPosition: 'top'},
    5: {stepNum: 5, img: '/assets/img/tutorial-mobile/12.png', language: "Click a section to preview a view from that section. Click “Select“ to find your seats in that particular section or click “Open Seat Map“ to manually select seats in that section.", arrowPosition: 'top'},
    6: {stepNum: 6, img: '/assets/img/tutorial-mobile/5.png', language: "Selected seats appear red with a checkmark. If needed, change your selection by clicking each seat.", arrowPosition: 'top'},
    7: {stepNum: 7, img: '/assets/img/tutorial-mobile/6.png', language: "Selected seats appear in the Cart. Click the map icon to go to that section in the map. Click the trash can to remove seats and make a different selection.", arrowPosition: 'bot'},
    8: {stepNum: 8, img: '/assets/img/tutorial-mobile/7.png', language: "When you are ready to purchase, click “Go to Checkout.”", arrowPosition: 'bot'},
    9: {stepNum: 9, img: '/assets/img/tutorial-mobile/8.png', language: "Review  your ticket information.", arrowPosition: 'bot'},
    10: {stepNum: 10, img: '/assets/img/tutorial-mobile/9.png', language: "Enhance your group outing with an optional upgraded ticket.", arrowPosition: 'top'},
    11: {stepNum: 11, img: '/assets/img/tutorial-mobile/10.png', language: "Protect your group outing with Rainout Insurance. Learn more by clicking the +Info button.", arrowPosition: 'top'},
    12: {stepNum: 12, img: '/assets/img/tutorial-mobile/11.png', language: "Review the details of your group ticket pricing. Click “Proceed to Payment” to complete your purchase.", arrowPosition: 'top'},
  };

  popperInstance;
  popover: HTMLElement = document.querySelector('.tutorialPopover');

  constructor(private tutorialService: TutorialService,
              private utilitiesService: UtilitiesService) { }

  ngOnInit(): void {
    // Device detection
    this.isMobile = this.utilitiesService.isMobile;
    if (this.isMobile) {
      this.tutorialSteps = this.tutorialStepsMobile;
    }

    this.actualStep = this.tutorialSteps[1];
    // console.log('Actual Step (Init): ', this.actualStep);
    this.totalSteps = Object.keys(this.tutorialSteps).length;


    //* Tooltip for Popper Library, future use
    // const tooltip: HTMLElement = document.querySelector('#tooltip');
    // // Initiate popover
    // this.popperInstance = createPopper(node, this.tooltip, {
    //   placement,
    //   modifiers: [
    //     {
    //       name: 'offset',
    //       options: {
    //         offset: [0, 8],
    //       },
    //     },
    //     {
    //       name: 'flip',
    //       options: {
    //         fallbackPlacements: [],
    //       }
    //     }
    //   ],
    // });
    // // Display popover
    // this.showPopover();
  }

  stepNext() {
    if (this.tutorialSteps[this.actualStep.stepNum + 1]) {
      this.actualStep = this.tutorialSteps[this.actualStep.stepNum + 1];
    }
    console.log('Actual Step (next): ', this.actualStep);
  }

  stepBack() {
    if (this.tutorialSteps[this.actualStep.stepNum - 1]) {
      this.actualStep = this.tutorialSteps[this.actualStep.stepNum - 1];
    }
    console.log('Actual Step (back): ', this.actualStep);
  }

  exitTutorial() {
    this.tutorialService.openTutorialExit();
    this.actualStep = this.tutorialSteps[1];
  }


  //* Tooltip for Popper Library, future use
  // // Display popover
  // showPopover() {
  //   this.popover.setAttribute('data-show', '');
  // }
  // // Remove popover
  // hidePopover() {
  //   this.popover.removeAttribute('data-show');
  // }
  // changeStep() {
  //   popperInstance.update();
  // }

}

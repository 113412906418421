import { Injectable, Inject } from '@angular/core';
declare var $: any;
// import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})

export class Popover3dviewService {

  popoverTimerTime = 100; // ms
  popoverTimer;
  currentView;

  constructor() { }

  setTimer() {
    this.popoverTimer = setTimeout(() => {
      this.timerFunction();
    }, this.popoverTimerTime);
  }

  deleteTimer() {
    if (this.popoverTimer) {
      clearTimeout(this.popoverTimer);
    }
  }

  timerFunction() {
    const tooltip: HTMLElement = document.querySelector('#tooltip');
    tooltip.removeAttribute('data-show');
  }

}

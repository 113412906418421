import { Component, OnInit } from '@angular/core';
import { UtilitiesService } from 'src/app/shared/services';
import { SeatAvailability } from "../../../shared/models";
import { DvmService } from '../../services/dvm.service';
import { Popover3dviewService } from '../../services/popover-3dview.service';

@Component({
  selector: 'app-seat-popover',
  templateUrl: './seat-popover.component.html',
  styleUrls: ['./seat-popover.component.scss']
})
export class SeatPopoverComponent implements OnInit {

  seatData: SeatAvailability;
  tooltipData: { section: string, row: string, seat: string, img: string };

  constructor(private dvmService: DvmService,
              private utilitiesService: UtilitiesService,
              private popover3dview: Popover3dviewService) {
  }

  ngOnInit(): void {
    // console.log('Seatn data: ', this.seatData);
    
    const seatInfo = this.utilitiesService.splittedSeatInfoFromId(this.seatData.mmcID);
    this.tooltipData = {
      section: seatInfo.section, 
      row: seatInfo.seat_row,
      seat: seatInfo.seat,
      img: ''
    };

    // this.viewer.getThumbnail(this.tooltipdata.section)
    this.dvmService.viewer.getThumbnail(this.seatData.mmcID)
    .then((img: any) => {
      this.tooltipData.img = img.src;
      // console.log('Tooltip data: ', this.tooltipData);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  onEnter() {
    this.popover3dview.deleteTimer();
  }

  onLeave() {
    this.popover3dview.setTimer();
  }

}
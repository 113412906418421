import { Injectable } from '@angular/core';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { AvailabilityService, CartService, UtilitiesService } from 'src/app/shared/services';
import { DvmService } from './dvm.service';
import { Seat } from "../../shared/models";
import { IsolatedSeatsService } from './isolated-seats.service';

@Injectable({
  providedIn: 'root'
})
export class SeatManagementService {

  viewer: any;

  constructor(private dvmService: DvmService,
              private cartService: CartService,
              private utilitiesService: UtilitiesService,
              private availabilityService: AvailabilityService,
              private isolatedSeatsService: IsolatedSeatsService,
              private modalsService: ModalsService) {
    this.dvmService.viewerSubject.subscribe({
      next: response => this.viewer = response,
      error: error => {
        console.error(error);
        const modalData = {
          title: "ERROR",
          content: "An Error occurred while trying to get the Viewer.",
          // closeBtnName: 'CLOSE',
          acceptBtnName: 'CLOSE',
          // acceptFunction: () => {this.goTo('checkout')}
        };
        this.modalsService.openModal(modalData);
      }
    });
  }

  selectAvailableSeats(nodeIds: string[]) {
    // console.log('Node Seats: ', nodeIds);

    // Reset selectedSeats if you are adding a group of seats (best available or area selection)
    if (nodeIds.length > 1) {
      this.viewer.unselectAll();
      this.cartService.selectedSeats = {};
      this.cartService.selectedSeats$.next(this.cartService.selectedSeats);
      // this.cartService.selectedSections = [];
    }

    // Select Seat(s) on Viewer
    let success = this.viewer.select(nodeIds);
    // console.log('Success SELECT Seat(s)? ', success);

    this.selectSeatsForCart(nodeIds);
  }

  unselectSelectedSeats(nodeIds: string[]) {
    // console.log('Node Seats: ', nodeId);
    // let clickedSeat = this.cartService.selectedSeats[nodeIds[0]];
    // if (clickedSeat !== undefined) {
      // Unselect Seat on Viewer
      let success = this.viewer.unselect(nodeIds);
      // console.log('Success UNSELECT Seat(s)? ', success);
    // }

    for (const nodeId of nodeIds) {
      // Remove one new seat (in Cart Service)
      let isDeleted = delete this.cartService.selectedSeats[nodeId];
      this.cartService.selectedSeats$.next(this.cartService.selectedSeats);
      // console.log('isDeleted? ', isDeleted);
    }
    // console.log('Selected Seats (Removing): ', this.cartService.selectedSeats);

    // Updating new seats total price
    this.cartService.selectedSeatsTotalPrice = this.utilitiesService.totalSumDvmSeatsPrice(this.cartService.selectedSeats);

    // Update cart Seats data
    this.updateCartSeatsBySection();

    if (this.dvmService.isSectionMap === true && !this.cartService.selectedSeatsBySection[this.dvmService.seatmapId]) {
      // Going back to Topview map
      // this.dvmService.loadMap();
    } else {
      // Update selected sections on Topview and MiniMap
      this.updateSelectedSectionsOnTopview();
    }
  }

  unselectAllSelectedSeats() {
    let success = this.viewer.unselectAll();
    // console.log('Success UNSELECT ALL Seats? ', success);

    // Reset new seats (in Transaction Service)
    this.cartService.selectedSeats = {};
    this.cartService.selectedSeats$.next(this.cartService.selectedSeats);
    // console.log('Selected Seats (Removing All): ', this.cartService.selectedSeats);

    // Updating new seats total price
    this.cartService.selectedSeatsTotalPrice = this.utilitiesService.totalSumDvmSeatsPrice(this.cartService.selectedSeats);

    // Update cart Seats data
    this.updateCartSeatsBySection();

    // Going back to Topview map
    this.dvmService.loadMap();
  }

  selectSeatsForCart(nodeIds: string[]) {
    // Add seats to Cart
    for (const nodeId of nodeIds) {
      // Merging new seat info from two objects:
      let newSeat = this.utilitiesService.splittedSeatInfoFromId(nodeId) as Seat;
      const seatInfo = this.availabilityService.getSeatByIdFromAvailability(nodeId)
      newSeat.price = seatInfo.price[0].value; //The first price is the default buyer type price.
      // Add one new seat (in Cart Service)
      this.cartService.selectedSeats[newSeat.id] = newSeat;
      this.cartService.selectedSeats$.next(this.cartService.selectedSeats);
    }

    // console.log('Selected Seats IDs: ', Object.keys(this.cartService.selectedSeats));
    // console.log('Selected Seats (Adding): ', this.cartService.selectedSeats);
    // console.log('Selected Sections (Adding): ', this.cartService.selectedSections);

    // Update cart Seats data
    this.updateCartSeatsBySection();

    // Updating new seats total price
    this.cartService.selectedSeatsTotalPrice = this.utilitiesService.totalSumDvmSeatsPrice(this.cartService.selectedSeats);
  }

  updateCartSeatsBySection() {
    this.cartService.selectedSeatsBySection = this.utilitiesService.distributeSeatsBySection(Object.values(this.cartService.selectedSeats));
    this.cartService.sortedSelectedSeatsBySectionArray = this.utilitiesService.sortSectionsBySeatsQuantity(this.cartService.selectedSeatsBySection);
    this.cartService.sortedSelectedSeatsBySectionArray$.next(this.cartService.sortedSelectedSeatsBySectionArray);
    // console.log('sortedSelectedSeatsBySectionArray', this.cartService.sortedSelectedSeatsBySectionArray);
    
    this.isolatedSeatsService.isolatedSeatsActive = false;
    this.isolatedSeatsService.lastIsolatedSeats = null;
    this.dvmService.viewer.removeNodesFromGroup(this.dvmService.viewer.getNodesByGroups('seat', 'pending'), 'pending');
  }

  updateSelectedSectionsOnTopview() {
    // Get selected sections from cartService
    const selectedSeatsSectionIds = Object.keys(this.cartService.selectedSeatsBySection);
    // console.log(selectedSeatsSectionIds);

    // Update selected sections on Topview
    let nodes = this.dvmService.viewer.getNodesByGroups('section', 'group1');
    this.dvmService.viewer.removeNodesFromGroup(nodes, 'group1');
    this.dvmService.viewer.addNodesToGroup(selectedSeatsSectionIds, 'group1');

    // Update selected sections on Mini Map
    // this.dvmService.viewerMini.unselectAll();
    // let success = this.dvmService.viewerMini.select(selectedSeatsSectionIds);
    // console.log('Success SELECT Seat(s)? ', success);
    // let selectedMini = this.dvmService.viewerMini.getNodesByState('section', 'selected');
    // console.log('Selected Sections Mini Map', selectedMini);
  }
}

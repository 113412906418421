import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth, { AuthState } from '@okta/okta-auth-js';
import { filter, map, Observable } from 'rxjs';
import { CartService } from '../shared/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  patron: any;
  name$!: Observable<string>;
  isAuthenticated$!: Observable<boolean>;
  isGuestSelected: boolean;
  // isTDCAcc: boolean;
  checkedUser: boolean;
  creatingAcc: boolean;
  validUser: boolean;

  transactionId: number | string;

  constructor(private router: Router,
              @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
              private _oktaAuthStateService: OktaAuthStateService,
              private _oktaStateService: OktaAuthStateService,
              private cartService: CartService) { }

  ngOnInit(): void {
    this.isAuthenticated$ = this._oktaStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );
    this.name$ = this._oktaAuthStateService.authState$.pipe(
      filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
      map((authState: AuthState) => authState.idToken?.claims.name ?? '')
    );
    console.log(this.name$);
  }

  async showPatron(): Promise<void> {
    this.patron = await this._oktaAuth.getUser()
    console.log(this.patron);
  }

  async signOut(): Promise<void> {
    await this._oktaAuth.signOut();
  }

  findTDCAcc() {
    // this.isTDCAcc = false;
    this.checkedUser = true;
  }

  createTDCAcc() {
    this.creatingAcc = true;
    this.validUser = true;
  }

  goTo(goPage: string) {
    this.router.navigate(['/', goPage, this.transactionId]);
  }

  backTo(backPage: string) {
    this.router.navigate(['/', backPage]);
  }

  switchLoginMethod() {
    this.isGuestSelected = !this.isGuestSelected;
  }

}

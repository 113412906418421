import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Seat, SeatsBySection } from '../models';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  selectedSeats: {[key: string]: Seat} = {};
  selectedSeats$: Subject<{[key: string]: Seat}> = new Subject<{[key: string]: Seat}>();

  selectedSeatsTotalPrice: number = 0;

  selectedSeatsBySection: {[key: string]: SeatsBySection} = {};

  sortedSelectedSeatsBySectionArray: SeatsBySection[] = [];
  sortedSelectedSeatsBySectionArray$: Subject<SeatsBySection[]> = new Subject<SeatsBySection[]>();
  
  constructor() { }

  resetCart() {
    this.selectedSeats = {};
    this.selectedSeatsTotalPrice = 0;
    this.selectedSeatsBySection = {};
    this.sortedSelectedSeatsBySectionArray = [];
  }

}
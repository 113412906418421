import { AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DvmService } from '../digital-venue/services/dvm.service';
import { IsolatedSeatsService } from '../digital-venue/services/isolated-seats.service';
import { ModalsService } from '../shared/modals/modals.service';
import { AvailabilityService, CartService, UtilitiesService } from '../shared/services';
import { ConnectionService } from '../shared/services/connection.service';
import { SaleEvent } from "../shared/models";
import { UpsellsService } from './upsells/upsells.service';
import { TutorialService } from '../shared/services/tutorial.service';

@Component({
  selector: 'app-seat-selection',
  templateUrl: './seat-selection.component.html',
  styleUrls: ['./seat-selection.component.scss']
})
export class SeatSelectionComponent implements OnInit, AfterViewInit {

  isMobile: boolean;
  activeTab: 'filters' | 'cart';
  openTabs: boolean;

  // eventId: number;
  event: SaleEvent;
  // html placement for popovers
  @ViewChild('dynamicPopover', { read: ViewContainerRef })
  public popoverPlacement: ViewContainerRef;

  get selectedSeats() {
    return Object.values(this.cartService.selectedSeats);
  };
  get totalSeatsPrice() {
    return this.cartService.selectedSeatsTotalPrice;
  }

  get is3dOpen() {
    return this.dvmService.is3dOpen;
  }

  get isTutorialOpen() {
    return this.tutorialService.isTutorialOpen;
  }

  constructor(private cartService: CartService,
              private connectionService: ConnectionService,
              private dvmService: DvmService,
              private isolatedSeatsService: IsolatedSeatsService,
              private modalsService: ModalsService,
              private availabilityService: AvailabilityService,
              private upsellsService: UpsellsService,
              private utilitiesService: UtilitiesService,
              private activatedRoute: ActivatedRoute,
              private tutorialService: TutorialService,
              private router: Router) { }

  ngAfterViewInit(): void {
    // set popover placement once the view has loaded
    this.dvmService.popoverPlacement = this.popoverPlacement;
  }

  ngOnInit(): void {
    // Device detection
    this.isMobile = this.utilitiesService.isMobile;
    this.activeTab = 'filters';
    this.openTabs = true;
    // Tutorial check
    this.tutorialService.checkTutorialOnStorage();

    // console.log(this.cartService.selectedSeats);
    this.cartService.resetCart();

    // Data "event" comes from Resolver
    // console.log(this.activatedRoute.snapshot);
    this.event = this.activatedRoute.snapshot.data['event'];
    // console.log('Event: ', this.event);
    this.upsellsService.upsells = this.event.upsells;
    this.availabilityService.minSeatsLimit = this.event.minTickets;
    this.availabilityService.maxSeatsLimit = this.event.maxTickets;
  }

  checkout() {
    if (this.selectedSeats.length >= this.event.minTickets && this.selectedSeats.length <= this.event.maxTickets) {

      const checkIsolatedSeats = () => {
        let isolatedSeats = this.isolatedSeatsService.checkIsolatedSeats(this.selectedSeats.map(a => a.id));
        isolatedSeats = [...new Set(isolatedSeats)]; // Remove duplicates
        // console.log(isolatedSeats);
        if (isolatedSeats && isolatedSeats.length) {
          // Modal
          const modalData = {
            title: "Wait! There's a problem",
            content: `The following seats are left isolated with your selection: ${isolatedSeats.map(a => a.split('S_')[1]).toString()}.
            Please, fix your selection before continuing to checkout.`,
            acceptBtnName: 'CLOSE'
          };
          this.modalsService.openModal(modalData);
          // Isolated seats
          this.isolatedSeatsService.isolatedSeatsActive = true;
          this.isolatedSeatsService.lastIsolatedSeats = isolatedSeats;
          this.dvmService.viewer.addNodesToGroup(isolatedSeats, 'pending');
        } else {
          this.goToCheckout();
        }
      }

      if (this.dvmService.viewer.getMapId() !== this.dvmService.loadOptions.map_id) {
        this.dvmService.loadMap().then(checkIsolatedSeats);
      } else {
        checkIsolatedSeats();
      }

    }
  }

  goToCheckout() {
    this.connectionService.createTransaction(this.event.pvEventId, Object.keys(this.cartService.selectedSeats)).subscribe({
      next: (response) => {
        console.log('TRANSACTION CREATED', response);
        if (response['result'].id && response['result'].token) {
          this.router.navigate(['/', 'checkout', response['result'].id], {queryParams: {'token': response['result'].token}});
        }
      },
      error: (error) => {
        console.error(error);
        console.error(error.error.message);
        const modalData = {
          title: "ERROR",
          content: 'An Error occurred while trying to create the Transaction.',
          acceptBtnName: 'CLOSE',
          // acceptFunction: ,
        };
        // If there's a custom api error.
        if (error.error.hasOwnProperty('code')) {
          modalData.content = error.error.message;
        };
        this.modalsService.openModal(modalData);
      }
    })
  }

  toggleShowTabs() {
    this.openTabs = !this.openTabs;
  }

  backToLanding() {
    this.router.navigate(['/events']);
  }

}

import { Injectable } from "@angular/core";
import { ModalsService } from "src/app/shared/modals/modals.service";
import { BestAvailableService } from "../services/best-available.service";
import { DvmService } from "../services/dvm.service";
import { SeatManagementService } from "../services/seat-management.service";
import { createPopper, Placement } from '@popperjs/core';
import { Popover3dviewService } from "../services/popover-3dview.service";
import { SectionPopoverComponent } from "../popovers/section-popover/section-popover.component";
import { AvailabilityService, UtilitiesService } from "../../shared/services";
import { SeatPopoverComponent } from "../popovers/seat-popover/seat-popover.component";

@Injectable()
export class HoverHandler {
  tooltip: HTMLElement;
  view3DId;
  descriptions;

  constructor(private dvmService: DvmService,
              private seatManagementService: SeatManagementService,
              private bestAvailableService: BestAvailableService,
              private availabilityService: AvailabilityService,
              private popover3dview: Popover3dviewService,
              private utilsService: UtilitiesService,
              private modalsService: ModalsService) {
    // CLICK subscribe
    this.dvmService.viewerSubject.subscribe({
      next: viewer => {
        viewer.subscribe('enter', this.handle.bind(this));
      },
      error: error => {
        console.error(error);
        const modalData = {
          title: "ERROR",
          content: "An Error occurred while trying to get the Viewer.",
          // closeBtnName: 'CLOSE',
          acceptBtnName: 'CLOSE',
          // acceptFunction: () => {this.goTo('checkout')}
        };
        this.modalsService.openModal(modalData);
      }
    });
  }

  private handle(obj) {
    if (obj.nodes.length && (obj.nodes[0].state === 'available' || obj.nodes[0].state === 'selected')) {
      this.popover3dview.deleteTimer();

      const node = obj.nodes[0];
      // Receive tooltip HTML Element could be done better todo
      this.tooltip = document.querySelector('#tooltip');
      if (!this.utilsService.isMobile) {
        this.dvmService.viewer.hover(node.id);
      }
      this.popover3dview.currentView = node.id;
      // Create content HTML Element, clean previous popover and append
      this.dvmService.popoverPlacement.clear()

      let offset = [0, -10];
      if (node.type === 'section' && obj.instance.getMapId() === this.dvmService.loadOptions.map_id) {
        const commponentRef=this.dvmService.popoverPlacement.createComponent<any>(SectionPopoverComponent);
        let sectionId=node.id;
        // Group info for different mmc sectors to the bleacher sector
        if (this.dvmService.GA_sectors.includes(node.id)) {
          this.dvmService.viewer.hover(this.dvmService.GA_sectors);
          sectionId = this.dvmService.bleacherSection;
        } else if (this.dvmService.GA_ada.includes(node.id)) {
          this.dvmService.viewer.hover(this.dvmService.GA_ada);
          sectionId = this.dvmService.bleacherAdaSection;
        }
        if (Object.keys(this.availabilityService.sectionsOnlyAdas).includes(sectionId)) {
          commponentRef.instance.sectionData = this.availabilityService.sectionsOnlyAdas[sectionId];
        } else {
          commponentRef.instance.sectionData = this.availabilityService.sectionsAvailability[sectionId];
        }
      } else if (node.type === 'seat') {
        offset = [0, 6];
        if (this.utilsService.isMobile) return;
        const commponentRef=this.dvmService.popoverPlacement.createComponent<any>(SeatPopoverComponent);
        commponentRef.instance.seatData = this.availabilityService.getSeatByIdFromAvailability(node.id);
      }

      // Calculate popover placement
      let placement: Placement;
      if (node.anchor.dom[1] > (document.getElementById('seat-selection').offsetHeight / 2)) {
        placement = 'top';
      } else {
        placement = 'bottom';
      }

      // Initiate popover
      createPopper(node, this.tooltip, {
        placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset,
            },
          },
          {
            name: 'flip',
            options: {
              fallbackPlacements: [],
            }
          }
        ],
      });

      // Display popover
      this.tooltip.setAttribute('data-show', '');
    }

  }
  sectionPopoverContent(node){
    return node.id;
  }
  seatPopoverContent(node) {
    return node.id;
  }
}

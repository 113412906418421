import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BestAvailableService } from 'src/app/digital-venue/services/best-available.service';
import { DvmService } from 'src/app/digital-venue/services/dvm.service';
import { AvailabilityService, CartService, UtilitiesService } from '../../shared/services';
import { UpsellsService } from '../upsells/upsells.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {

  isMobile: boolean;

  tutorialOn: boolean = false;

  needADA: boolean;

  get minSeatsLimit() {
    // console.log('min: ', this.availabilityService.minSeatsLimit);
    return this.availabilityService.minSeatsLimit;
  };
  get maxSeatsLimit() {
    return this.availabilityService.maxSeatsLimit;
  };

  // get totalMinPrice() {
  //   return this.availabilityService.totalMinSeatPrice;
  // };
  // get totalMaxPrice() {
  //   return this.availabilityService.totalMaxSeatPrice;
  // };
  // get minSeatPrice() {
  //   return this.availabilityService.minSeatPrice;
  // };
  // get maxSeatPrice() {
  //   // console.log('MAX: ', this.availabilityService.maxSeatPrice);
  //   return this.availabilityService.maxSeatPrice;
  // };

  minValue: number;
  maxValue: number;
  sliderOptions: Options;
  loadSlider: boolean;

  get numberSeats() {
    return this._numberSeats;
  }
  set numberSeats(value) {
    this._numberSeats = value;
    // console.log(this._numberSeats);
  }

  _numberSeats: number;
  numberAccessibleSeats: number;

  // Cart seats
  cartSeats: number = 0;

  private onlyNumbers = new RegExp('^[0-9]+$');

  constructor(private dvmService: DvmService,
              private cartService: CartService,
              private availabilityService: AvailabilityService,
              private upsellsService: UpsellsService,
              private bestAvailableService: BestAvailableService,
              private utilitiesService: UtilitiesService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    // Device detection
    this.isMobile = this.utilitiesService.isMobile;

    this.sliderOptions = {
      floor: 0,
      ceil: 1000
    };
    this.minValue = 0;
    this.maxValue = 1000;

    this.availabilityService.priceRange$.subscribe({
      next: response => {
        this.sliderOptions = {
          floor : response.min,
          ceil : response.max
        }
        this.minValue = response.min;
        this.maxValue = response.max;
        // console.log('min-MAX Value', this.minValue, this.maxValue);
        // console.log('Options', this.sliderOptions);
      },
    });

    // let data = this.route.snapshot.data['event'];
    // console.log('DATA: ', data);

    this.numberSeats = this.minSeatsLimit;
    this.numberAccessibleSeats = 0;
    this.needADA = false;
    // console.log('numbSeats: ', this.numberSeats);

    //* UPSELLS
    // Subcribe to cart total seats changes to update Upsells benefits list
    this.cartService.selectedSeats$.subscribe({
      next: response => {
        // console.log('Selected Seats: ', response);
        // this.upsellsService.totalSeats.next(Object.keys(response).length);
        this.cartSeats = Object.keys(response).length;
      },
      error: error => console.error(error)
    });

  }


  onUserChangeEnd(changeContext: ChangeContext): void {
    // console.log('User Change END', changeContext);
    // this.availabilityService.minSeatPrice = changeContext.value;
    // this.availabilityService.maxSeatPrice = changeContext.highValue;

    console.log('min-Max Price filter: ', this.minValue, this.maxValue);

    // New Availability
    let newAvailability = [];
    Object.entries(this.availabilityService.sectionsAvailability).forEach(
      ([mmcId, data]) => {
        Object.values(data.priceScales).forEach(
          (priceScale) => {
            // if section has price between this values
            if (priceScale.price.value <= this.maxValue && priceScale.price.value >= this.minValue) {
              newAvailability.push(data.mmcID);
            }
          }
        );
      }
    );
    // map mmc ga separated sectors to bleacher
    if (newAvailability.includes(this.dvmService.bleacherSection)) {
      if (this.availabilityService.sectionsAvailability[this.dvmService.bleacherSection].availableQuantityAda > 0) {
        newAvailability = newAvailability.concat(this.dvmService.GA_ada);
      }
      if (this.availabilityService.sectionsAvailability[this.dvmService.bleacherSection].availableQuantity -
        this.availabilityService.sectionsAvailability[this.dvmService.bleacherSection].availableQuantityAda > 0) {
          newAvailability = newAvailability.concat(this.dvmService.GA_sectors);
      }
      // newAvailability = newAvailability.concat(this.dvmService.GA_sectors).concat(this.dvmService.GA_ada);
    }
    this.dvmService.viewer.setAvailability("section", newAvailability);
    // restart nodes selected for best available
    this.bestAvailableService.selectedSectionsForBA = [];
  }

  addBestSeats() {
    this.stringTypeSavior();
    this.stringTypeSaviorADA();
    // https://mmcbcn.atlassian.net/browse/IT-2748 ada included in the best available number of seats
    const totalNumberOfSeats =  this.numberSeats - this.numberAccessibleSeats;
    this.bestAvailableService.getBestXSeats(totalNumberOfSeats, this.numberAccessibleSeats);
  }

  /**
   * BA algorithm can only be executed in the top view.
   * When user enter on section or 3d, disable button
   * @returns
   */
  bestAvailableIsAvailable(): boolean {
    return this.dvmService.isSectionMap || this.dvmService.is3dOpen;
  }

  // Add regular seat to user selection
  oneSeatLess() {
    this.stringTypeSavior();
    if (this.numberSeats > this.minSeatsLimit && this.numberSeats <= this.maxSeatsLimit) {
      this.numberSeats -= 1;
    } else {
      this.numberSeats = this.minSeatsLimit;
    }
    this.checkRegularSeatsHigherThanAda();
    //todo Updating totalSeats number for Upsells (plus cart seats that you've already selected)
    this.upsellsService.sendLastUpsellsCall(this.numberSeats + this.cartSeats);
  }

  onFocusOut(type: 'regular' | 'ada' = 'regular') {
    if (type === 'regular') {
      this.stringTypeSavior();
      this.checkRegularSeatsHigherThanAda();
      //todo Updating totalSeats number for Upsells (plus cart seats that you've already selected)
      this.upsellsService.sendLastUpsellsCall(this.numberSeats + this.cartSeats);
    } else if (type === 'ada') {
      this.stringTypeSaviorADA();
    }
  }

  // Remove regular seat to user selection
  oneSeatMore() {
    this.stringTypeSavior();
    if (this.numberSeats >= this.minSeatsLimit && this.numberSeats < this.maxSeatsLimit) {
      this.numberSeats += 1;
    } else {
      this.numberSeats = this.maxSeatsLimit;
    }
    //todo Updating totalSeats number for Upsells (plus cart seats that you've already selected)
    this.upsellsService.sendLastUpsellsCall(this.numberSeats + this.cartSeats);
  }

  // Add accessible seat to user selection
  oneAccSeatLess() {
    this.stringTypeSaviorADA(); //* prevent string values on input
    if (this.numberAccessibleSeats > 0 && this.numberAccessibleSeats <= this.numberSeats) {
      this.numberAccessibleSeats -= 1;
    }
  }

  // Remove accessible seat to user selection
  oneAccSeatMore() {
    this.stringTypeSaviorADA(); //* prevent string values on input
    if(this.numberAccessibleSeats >= 0 && this.numberAccessibleSeats < this.numberSeats) {
      this.numberAccessibleSeats  += 1
    }
  }

  closeAdaSelector(): void {
    this.needADA = false;
    this.numberAccessibleSeats = 0;
    this.availabilityService.deactivateOnlyAdaSections();
  }

  activeAda(): void {
    this.needADA = true;
    this.availabilityService.activeAdaSections();
  }

  private stringTypeSavior() {
    if (typeof(this.numberSeats) === 'string') {
      if (this.numberSeats === '' || parseInt(this.numberSeats) < this.minSeatsLimit) {
        this.numberSeats = this.minSeatsLimit;
      } else if (parseInt(this.numberSeats) > this.maxSeatsLimit) {
        this.numberSeats = this.maxSeatsLimit;
      } else {
        this.numberSeats = parseInt(this.numberSeats);
      }
    }
  }

  private stringTypeSaviorADA() {
    if (typeof(this.numberAccessibleSeats) === 'string') {
      if (this.numberAccessibleSeats === '' || parseInt(this.numberAccessibleSeats) < 0) {
        this.numberAccessibleSeats = this.minSeatsLimit;
      } else if (parseInt(this.numberAccessibleSeats) > this.numberSeats) {
        this.numberAccessibleSeats = this.numberSeats;
      } else {
        this.numberAccessibleSeats = parseInt(this.numberAccessibleSeats, 10) ;
      }
    }
  }

  // If the number of regular seats is lower than the number of ada seats,
  // the ada seats cannot be higher than the number of seats, we must equal both values.
  private checkRegularSeatsHigherThanAda(): void {
    if (this.numberSeats < this.numberAccessibleSeats) {
      this.numberAccessibleSeats = this.numberSeats;
    }
  }

  // For input numbers fixing. Actually using the Numbers Input directive
  // seatsDataChanged(value) {
  //   // console.log('Input value: ', value);
  //   // console.log('numberSeats: ', this.numberSeats);
  //   let inputValue = value;
  //   // let inputValue = this.numberSeats.toString();
  //   let goodResult = '';

  //   if (this.onlyHasNumbers(inputValue) === false) {
  //     for (let i = 0; i < inputValue.length; i++) {
  //       // console.log('condition', this.onlyHasNumbers(inputValue[i]) === false);
  //       if (this.onlyHasNumbers(inputValue[i]) === true) {
  //         // console.log('is Number', inputValue[i]);
  //         goodResult = goodResult.concat(inputValue[i]);
  //       }
  //     }
  //     console.log('goodResult: ', goodResult);
  //     if (goodResult) {
  //       this.numberSeats = parseInt(goodResult);
  //     } else {
  //       this.numberSeats = this.minSeatsLimit;
  //     }
  //     document.getElementById('input-seats')['value'] = this.numberSeats;
  //   }
  //   // this.stringTypeSavior();
  //   console.log('Number Seats: ', this.numberSeats);
  // }

  // /**
  //  * Check using RegExp if string has only numbers
  //  * @param {string} value - item to test
  //  * @returns {boolean} - true if only has numbers, otherwise false
  // */
  // onlyHasNumbers(value): boolean {
  //   return this.onlyNumbers.test(value);
  // }

}

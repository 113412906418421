import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DvmService } from 'src/app/digital-venue/services/dvm.service';
import { UtilitiesService } from '../../services';

@Component({
  selector: 'app-tutorial-welcome-modal',
  templateUrl: './tutorial-welcome-modal.component.html',
  styleUrls: ['./tutorial-welcome-modal.component.scss']
})
export class TutorialWelcomeModalComponent implements OnInit {

  isMobile: boolean;

  title: string;
  content: string;
  closeBtnName: string;
  closeFunction: any;
  acceptBtnName: string;
  acceptFunction: any;

  // Legend
  availableColor: string;
  mySeatsColor: string;
  selectedColor: string;
  isolatedColor: string;

  get isTopview() {
    return this.dvmService.isTopview;
  }

  constructor(public bsModalRef: BsModalRef,
              private utilitiesService: UtilitiesService,
              private dvmService: DvmService) {
    this.availableColor = 'blue-light';
    this.mySeatsColor = 'red';
    this.selectedColor = 'purple';
    this.isolatedColor = 'orange';
  }

  ngOnInit(): void {
    // Device detection
    this.isMobile = this.utilitiesService.isMobile;
  }

  accept() {
    this.bsModalRef.hide();
    if (this.acceptFunction) {
      this.acceptFunction();
    }
  }

  close() {
    this.bsModalRef.hide();
    if (this.closeFunction) {
      this.closeFunction();
    }
  }
}

<!-- Header -->
<app-header></app-header>

<!-- Loading screen -->
<!-- <app-loader></app-loader> -->

<!-- Inserted by route -->
<div *ngIf="!isMobile" id="main" class="w-full fixed top-16 overflow-auto">
  <router-outlet></router-outlet>
</div>

<div *ngIf="isMobile" id="main-mobile" class="w-full fixed top-12 overflow-y-auto">
  <router-outlet></router-outlet>
</div>

<!-- FOOTER --->
<app-footer></app-footer>

<!-- Error -->
<!-- <app-error-modal></app-error-modal> -->

import { Injectable } from '@angular/core';
import { map, ReplaySubject } from 'rxjs';
import { DvmService } from 'src/app/digital-venue/services/dvm.service';
import { ModalsService } from '../modals/modals.service';
import { ConnectionService } from './connection.service';
import { SeatAvailabilityDict, SeatAvailabilitySectionDict, GeneralAvailabilityDict, SaleEvent} from "../models";
import { PriceRange } from '../models/seat-availability.model';

@Injectable({
  providedIn: 'root'
})
export class AvailabilityService {

  viewer: any;
  sectionsAvailability: GeneralAvailabilityDict = {};  // Coming from load handler.
  seatsAvailability: SeatAvailabilitySectionDict = {};  // Coming from load handler.
  sectionsOnlyAdas: GeneralAvailabilityDict = {};
  // Stored from Seat selection init
  minSeatsLimit: number;
  maxSeatsLimit: number;

  // Price range filter
  priceRange$ = new ReplaySubject<PriceRange>(1);
  priceRange: PriceRange = {min: 100000000, max: -1}; // Están seteados así para que cambien al compararlos con los valores de los tickets.

  adaSeats = [];


  public test:{min:number,max: number} = {min:100000000,max:-1};

  constructor(private dvmService: DvmService,
              private connectionService: ConnectionService,
              private modalsService: ModalsService) {
    this.dvmService.viewerSubject.subscribe({
      next: response => this.viewer = response,
      error: error => {
        console.error(error);
        const modalData = {
          title: "ERROR",
          content: "An Error occurred while trying to get the Viewer.",
          acceptBtnName: 'CLOSE',
        };
        this.modalsService.openModal(modalData);
      }
    });
  }

  getSectionAvailability(eventId = this.connectionService.eventId) {
    return this.connectionService.getGeneralAvailability(eventId).pipe(
      map((availability: GeneralAvailabilityDict) => {
        const response = [];
        Object.entries(availability).forEach(
          ([mmcId, data]) => {
            if (data.availableQuantity + data.availableQuantityAda === 0) {
              console.log('DELETED', mmcId);

              delete availability[mmcId];
            } else {
              if (data.availableQuantity === 0 && data.availableQuantityAda > 0) {
                this.sectionsOnlyAdas[mmcId] = data;
                delete availability[mmcId];
                return
              }
              if (data.priceRange[0].value < this.priceRange.min) {
                this.priceRange.min = data.priceRange[0].value;
              }
              if (data.priceRange[1].value > this.priceRange.max) {
                this.priceRange.max = data.priceRange[1].value;
              }
              this.priceRange$.next(this.priceRange);
              response.push(mmcId);
            }
          }
        );
        this.sectionsAvailability = availability;
        // console.log(response);
        return response;
      })
    );
  }

  getSeatAvailability(sectionsArray: string[], eventId = this.connectionService.eventId) {
    console.log(sectionsArray);
    return this.connectionService.getMultiSectionAvailability(eventId, sectionsArray).pipe(
      map(
        (availability: SeatAvailabilityDict) => {
          let response = [];
          console.log('Multi section availability', availability);
          sectionsArray.forEach(sectionId => {
            // Filter availability for this specific section
            const filteredAvailability = Object.entries(availability).filter(([seatId, _]) => seatId.split('-')[0] === sectionId);
            this.seatsAvailability[sectionId] = Object.fromEntries(filteredAvailability);
          });
          response = Object.keys(availability);
          return response;
        }
      )
    );
  }

  activeAdaSections() {
    this.dvmService.viewer.setAvailable("section", Object.keys(this.sectionsOnlyAdas));
  }

  deactivateOnlyAdaSections() {
    this.dvmService.viewer.setUnavailable("section", Object.keys(this.sectionsOnlyAdas));
  }



  //* AVAILABILITY REQUESTS

  // getAvailability(nodeType: string) {
  //   return this.getFakeAvailability(nodeType);
  // }

  // customer-plan/7315/availability/
  // getFakeAvailability(nodeType: string) {
  //   let nodes = [];
  //   if (nodeType === 'section') {
  //     nodes = this.getFakeSectionAvailability();
  //   } else if (nodeType === 'seat') {
  //     nodes = this.getFakeSeatAvailability();
  //   }
  //   // console.log('Nodes: ', nodes);
  //   const result: any = {};
  //   nodes.forEach(node => result[node] = true)
  //   // console.log('Result: ', result);
  //
  //   return new Observable((subscriber) => {
  //     subscriber.next(result);
  //     subscriber.complete();
  //   });
  // }


  // ---- FAKE AVAILABILITY FUNCTIONS ----

  // // Get sections availability. For the purpose, we generate a RANDOM availability.
  // getFakeSectionAvailability() {
  //   var sections = this.viewer.getNodesByType("section");
  //   var available_sections = [];
  //
  //   for (var i = 0; i < sections.length; ++i) {
  //     var section = sections[i];
  //     if (Math.random() < 0.7) {
  //       available_sections.push(section.id);
  //     }
  //   }
  //
  //   return available_sections;
  // }
  //
  // // Get seats availability. For the purpose, we generate a RANDOM availability.
  // getFakeSeatAvailability() {
  //   // Only generate availability for seats with an AVAILABLE parent section
  //   var available_sections = this.viewer.getNodesByState("section", "available");
  //   var available_seats = [];
  //
  //   for (var i = 0; i < available_sections.length; ++i) {
  //     var section = available_sections[i];
  //     var seats = this.viewer.getNodesByParent(section.id);
  //     for (var j = 0; j < seats.length; ++j) {
  //       var seat = seats[j];
  //       if (Math.random() < 0.7) {
  //         available_seats.push(seat.id);
  //       }
  //     }
  //   }
  //
  //   this.totalMinSeatPrice = 45;
  //   this.totalMaxSeatPrice = 98;
  //   this.minSeatPrice = this.totalMinSeatPrice;
  //   this.maxSeatPrice = this.totalMaxSeatPrice;
  //   // console.log(this.minSeatPrice, this.maxSeatPrice);
  //
  //   return available_seats;
  // }

  getSeatByIdFromAvailability(seatId: string) {
    return this.seatsAvailability[seatId.split('-')[0]][seatId];
  }

}

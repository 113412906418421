import { Injectable } from "@angular/core";
import { ModalsService } from "src/app/shared/modals/modals.service";
import { UtilitiesService } from "src/app/shared/services";
import { BestAvailableService } from "../services/best-available.service";
import { DvmService } from "../services/dvm.service";
import { SeatManagementService } from "../services/seat-management.service";

@Injectable()
export class ClickHandler {

  constructor(private dvmService: DvmService,
              private seatManagementService: SeatManagementService,
              private bestAvailableService: BestAvailableService,
              private utilitiesService: UtilitiesService,
              private modalsService: ModalsService) {
    // CLICK subscribe
    this.dvmService.viewerSubject.subscribe({
      next: viewer => {
        viewer.subscribe('click', this.handle.bind(this));
      },
      error: error => {
        console.error(error);
        const modalData = {
          title: "ERROR",
          content: "An Error occurred while trying to get the Viewer.",
          // closeBtnName: 'CLOSE',
          acceptBtnName: 'CLOSE',
          // acceptFunction: () => {this.goTo('checkout')}
        };
        this.modalsService.openModal(modalData);
      }
    });
  }

  private handle(obj) {
    let viewer = obj.instance;
    let node = obj.nodes[0];
    // console.log('Node: ', node);

    if (node) {
      // console.log('Node: ', node);
      // //* CLICKING SECTION NODE
      // if ga show modal ga selection
      if (this.dvmService.GA_sectors.includes(node.id) ){
        const modalData = {
          title: "General Admission Section",
          content: 'Please, select the number of seats to add from the General Admission section.',
          closeBtnName: 'CANCEL',
          acceptBtnName: 'CONFIRM',
          isAda: false,
          acceptFunction: () => {},
        };
        this.modalsService.openGaModal(modalData);
        return;
      }
      if (this.dvmService.GA_ada.includes(node.id) ){
        const modalData = {
          title: "General Admission Section (ADA)",
          content: 'Please, select the number of ADA locations to add from the General Admission section.',
          closeBtnName: 'CANCEL',
          acceptBtnName: 'CONFIRM',
          isAda: true,
          acceptFunction: () => {},
        };
        this.modalsService.openGaModal(modalData);
        return;
      }

      if (node.type === 'section' && !this.utilitiesService.isMobile) {
        if (node.state === 'available') {
          console.log('Node Section Available', node);
          // this.dvmService.loadMap(viewer, node.id);
          this.bestAvailableService.selectSectionForBA(node.id);
        } else if (node.state === 'selected') {
          // console.log('Node Section Selected', node);
          this.bestAvailableService.unselectSectionForBA(node.id);
        }
      }

      // //* CLICKING SEAT NODE
      if(node.type === 'seat') {
        if (node.state === 'available') {
          // console.log('Node Seat Available', node);
          this.seatManagementService.selectAvailableSeats([node.id]);
        } else if(node.state === 'selected') {
          // console.log('Node Seat Selected', node);
          this.seatManagementService.unselectSelectedSeats([node.id]);
        }
      }
    }
  }

}

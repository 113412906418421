<div id="cart" [ngClass]="isMobile? 'h-full': 'mb-3 3xl:mb-4 bg-white rounded-2.5xl overflow-hidden'">

  <!-- header -->
  <div id="cart-header" class="w-full px-6 py-2 flex justify-between items-center bg-red text-white" [ngClass]="[isMobile? 'h-12': 'h-14 3xl:h-16', (isMobile && seats.length === 0)? 'hidden': 'block']">
    <div class="mr-5">
      <h5 *ngIf="!isMobile">Your Cart</h5>
      <p>Accept 'Best Available' or select your own seats.</p>
    </div>
    <button class="btn-white h-9 disabled:text-gray-400 disabled:bg-gray-100" (click)="removeAllFromSelectedSeats()" [disabled]="seats.length === 0">
      Remove all
    </button>
  </div>

  <!-- body -->
  <div id="cart-body" class="w-full text-black bg-white overflow-y-auto" [ngClass]="isMobile? '': 'h-36 3xl:h-44'">
    <div *ngIf="seats.length === 0" class="px-6 py-2" [ngClass]="isMobile? '': 'h-full'">
      <h5 class="my-4 font-normal text-center text-gray-400">Please select number of seats and price above first.</h5>
    </div>

    <ul *ngIf="seats.length !== 0" class="overflow-hidden">
      <li *ngFor="let section of seatsBySection">

        <div class="max-w-full px-2 py-1 flex justify-between items-center border-b border-gray-300 last:border-0" [ngClass]="isMobile? 'w-full min-height-12': 'h-12 3xl:h-14'">
          <h5 class="font-bold" [ngClass]="isMobile? '': 'ml-2'">
            <span class="font-normal text-gray-600" [ngClass]="isMobile? 'mr-1': 'mr-3'">Section</span>{{ section.id.slice(2) }}
          </h5>
          <div class="w-auto flex items-center">
            <div class="flex items-center" [ngClass]="isMobile? 'flex-wrap justify-center': 'ml-5'">
              <div class="w-25 min-w-max h-8 flex justify-center items-center border border-gray-300 rounded-full" [ngClass]="isMobile? 'px-3 ml-3': 'px-4'">
                <!-- <input type="text" [(ngModel)]="numberAccessibleSeats" class="w-8 text-sm  text-center text-black bg-white"> -->
                <img src="/assets/img/chair-blue-icon.svg" alt="blue chair icon" class="h-3">
                <h5 class="min-w-max ml-1 font-bold text-blue">{{ section.totalSectionSeats }} seats</h5>
              </div>
              <div [ngClass]="isMobile? 'ml-3': 'ml-5'">
                <h5 class="font-bold text-blue">${{ section.totalSectionPrice | number: '1.2-2' }}</h5>
              </div>
            </div>
            <div class="flex" [ngClass]="isMobile? 'ml-1': 'ml-3'">
              <button class="btn-icon bg-white hover:bg-gray-200" [ngClass]="isMobile? 'ml-1': 'ml-2'" (click)="removeSectionSeatsFromSelectedSeats(section)">
                <img src="/assets/img/delete-bin-icon.svg" alt="delet bin icon">
              </button>
              <button class="btn-icon hover:bg-gray-200" [ngClass]="isMobile? 'ml-1': 'ml-2'">
                <img src="/assets/img/location-sharp-icon.svg" alt="location icon" *ngIf="!isGASection(section.id) else gaLocationImage" (click)="goToSeatmap(section.id)">
                <ng-template #gaLocationImage>
                  <img src="/assets/img/location-sharp-icon-disabled.svg" alt="location icon" class="cursor-not-allowed" *ngIf="isGASection(section.id)">
                </ng-template>
              </button>
              <button class="btn-icon hover:bg-gray-200 no-rotate" [ngClass]="isMobile? 'ml-1': 'ml-2'" #arrow (click)="toggleSectionList(section.id, arrow)">
                <img src="/assets/img/arrow-down-blue-icon.svg" alt="blue downward arrow icon">
              </button>
            </div>
          </div>
        </div>

        <!-- <ul class="bg-gray-100 overflow-y-auto accordeon-content" [ngClass]="{hidden: !sectionsListOpen.includes(section.id)}"> -->
        <ul class="bg-gray-100" [ngClass]="{hidden: !sectionsListOpen.includes(section.id)}">
          <div *ngFor="let row of section.rows">
            <li *ngFor="let seat of row.seats" class="h-14 px-2 py-1 flex justify-between items-center border-b border-gray-300">
              <h5 class="font-bold">
                <span class="font-normal text-gray-500" [ngClass]="isMobile? ' mr-1': 'ml-2 mr-3'">Section</span>
                {{ seat.section.slice(2) }}
              </h5>
              <h5 class="font-bold">
                <span class="font-normal text-gray-500" [ngClass]="isMobile? 'ml-1 mr-1': 'ml-2 mr-3'">Row</span>
                {{ seat.seat_row }}
              </h5>
              <h5 class="font-bold">
                <span class="font-normal text-gray-500" [ngClass]="isMobile? 'ml-1 mr-1': 'ml-2 mr-3'">Seat</span>
                {{ seat.seat }}
              </h5>
              <div class="flex items-center">
                <h5 class="ml-2 font-bold text-blue">${{ seat.price | number: '1.2-2' }}</h5>
                <button class="btn-icon hover:bg-gray-200" [ngClass]="isMobile? 'ml-2': 'ml-5'" (click)="removeOneFromSelectedSeats(seat)">
                  <img src="/assets/img/delete-bin-icon.svg" alt="delete bin icon">
                </button>
                <div class="btn-icon" [ngClass]="isMobile? 'ml-1': 'ml-2'">
                  <img *ngIf="isAda(seat.id)" src="/assets/img/accessible-icon.svg" alt="accessible seating">
                </div>
                <div class="btn-icon" [ngClass]="isMobile? 'ml-1': 'ml-2'">
                  <button (click)="load3dView(seat.id)" class="btn-icon bg-white hover:bg-gray-200">
                    <img src="/assets/img/feather-eye.svg" alt="location icon">
                  </button>
                </div>
              </div>
            </li>
          </div>
        </ul>

      </li>
    </ul>

    <!--* SUMMARY -->
    <div *ngIf="isMobile" class="px-4 py-2">
      <h4 class="my-4 font-bold text-center">Price Balance</h4>
      <div class="px-6 py-4 bg-blue text-white rounded-2.5xl">
        <div class="mb-4 flex justify-center items-center">
          <div class="mr-4 flex items-center">
            <h5 class="mr-3 flex items-center">Total Seats
              <span class="w-3 mx-1"><img src="/assets/img/chair-white-icon.svg" alt="white chair icon" class="w-3"></span>:
            </h5>
            <div class="w-16 h-8 px-4 flex justify-center items-center border rounded-full">
              <h5>{{ seats.length }}</h5>
              <!-- <input type="text" [(ngModel)]="seats.length" class="w-8 text-sm font-bold text-center bg-blue"> -->
            </div>
          </div>
          <div class="text-center">
            <h4 class="text-sm 3xl:text-base font-normal">Ticket Total:</h4>
            <h4>$ {{ totalSeatsPrice | number: '1.2-2' }}</h4>
          </div>
        </div>
        <div>
          <!-- <button class="btn-white w-40 font-bold disabled:opacity-20" (click)="checkout()" [disabled]="seats.length < 15">Go to Checkout</button> -->
          <button class="btn-white w-40 mx-auto font-bold disabled:opacity-20" (click)="checkoutMob()"
          [disabled]="seats.length < minSeatsLimit || seats.length > maxSeatsLimit">Go to Checkout
          </button>
        </div>
      </div>
    </div>

  </div>

</div>

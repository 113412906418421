import { Injectable } from '@angular/core';
import { ModalsService } from '../modals/modals.service';

@Injectable({
  providedIn: 'root'
})
export class TutorialService {

  public isTutorialOpen: boolean = false;

  constructor(private modalsService: ModalsService) { }

  checkTutorialOnStorage() {
    // Tutorial check
    if (localStorage.getItem('isTutorialDone') !== 'yes') {
      localStorage.setItem('isTutorialDone', 'yes');
      const tutorial = localStorage.getItem('isTutorialDone');
      // Open tutorial
      this.openTutorialInit();
    }
  }

  openTutorialInit() {
    const modalData = {
      acceptFunction: this.openTutorial.bind(this),
    };
    this.modalsService.openTutorialWelcomeModal(modalData);
  }

  openTutorial() {
    this.isTutorialOpen = true;
    console.log('isTutorialOpen? ', this.isTutorialOpen);
  }

  openTutorialExit() {
    const modalData = {
      title: `Tutorial complete!`,
      content: "",
      closeBtnName: 'Repeat',
      acceptBtnName: 'Buy Tickets',
      closeFunction: this.openTutorial.bind(this),
      acceptFunction: this.closeTutorial.bind(this),
    };
    this.modalsService.openModal(modalData);
  }

  closeTutorial() {
    this.isTutorialOpen = false;
    console.log('isTutorialOpen? ', this.isTutorialOpen);
  }

}

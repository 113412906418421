// import { loadModule } from '@3ddv/dvm-internal';
import { Component, OnInit } from '@angular/core';
import { DvmService } from 'src/app/digital-venue/services/dvm.service';
import * as neighbors from 'src/assets/data/neighbors.json';
import { SelectionAreaEndHandler, LeaveHandler, HoverHandler, LoadHandler, ClickHandler } from 'src/app/digital-venue/handlers';
import { UtilitiesService } from 'src/app/shared/services';

@Component({
  selector: 'app-dvm',
  templateUrl: './dvm.component.html',
  styleUrls: ['./dvm.component.scss'],
  providers: [  //? Es necesario proveer los handlers en el componente dónde se van a activar para reconocerlos.
    LoadHandler,
    HoverHandler,
    LeaveHandler,
    SelectionAreaEndHandler,
    ClickHandler
  ]
})
export class DvmComponent implements OnInit {

  isMobile: boolean;

  neighbors: { [x: string]: { [x: string]: string; }; };
  currentSection: string;

  get isSectionMap() {
    return this.dvmService.isSectionMap;
  }

  get is3dOpen() {
    return this.dvmService.is3dOpen;
  }

  constructor(private dvmService: DvmService,
              private utilitiesService: UtilitiesService,
              private loadHandler: LoadHandler,  //? Es necesario importar los hanlders en el constructor para que se ejecuten.
              private leaveHandler: LeaveHandler,
              private selectionArea: SelectionAreaEndHandler,
              private enterHandler: HoverHandler,
              private clickHandler: ClickHandler) { }

  ngOnInit(): void {
    // Device detection
    this.isMobile = this.utilitiesService.isMobile;

    // DVM
    this.dvmService.initializeDVM();
    this.dvmService.initializeDVMMiniMap();
    this.neighbors = neighbors['default'];
    const subscribeToMapChange = () => {
      this.dvmService.viewer.subscribe('end_load', this.updateCurrentSection.bind(this));
    }
    if (this.dvmService.viewer) {
      subscribeToMapChange();
    } else {
      this.dvmService.viewerSubject.subscribe(subscribeToMapChange);
    }
  }

  updateCurrentSection() {
    this.currentSection = this.dvmService.viewer.getMapId();
    // console.log('CURRENT SECTION', this.currentSection);
  }

  back() {
    if (this.dvmService.is3dOpen) {
      // Close 3D
      this.dvmService.close3dView();
    } else {
      this.dvmService.loadMap();
    }
  }

  changeSection(next: any) {
    let currentSection = this.dvmService.viewer.getMapId();
    if (this.neighbors[currentSection]) {
      if (next && this.neighbors[currentSection]['r']) {
        this.dvmService.loadMap(this.neighbors[currentSection]['r']);
      } else if (!next && this.neighbors[currentSection]['l']) {
        this.dvmService.loadMap(this.neighbors[currentSection]['l']);
      }
    }
  }

}

<div id="seat-selection" class="h-full">
  <!-- Overlay test for Tutorial -->
  <!-- <div class="h-full z-20 absolute top-0 left-0 right-0 bottom-0 backdrop-filter backdrop-blur-2xl"></div> -->
  <!-- <div class="h-full z-20 absolute top-0 left-0 right-0 bottom-0 bg-black filter blur-2xl"></div> -->

  <!--* MAP -->
  <div id="map" [ngClass]="[isMobile? 'h-1/2': 'h-full map', isMobile && !openTabs? 'full-screen-map transform duration-700': 'transform duration-700']">
    <app-dvm></app-dvm>
  </div>

  <!--* SEATS DATA -->
  <div *ngIf="!isMobile" id="seats-data-container" class="w-full h-full absolute top-0 pointer-events-none">
    <div id="seats-data-side" class="w-max h-full px-7 py-2 2xl:p-7 flex flex-col items-center text-white overflow-y-auto flipped-rtl pointer-events-auto">
      <div class="w-full flex flex-row mb-4 justify-end">
        <button class="button-home flex flex-row justify-evenly items-center" (click)="backToLanding()">
          <span>Home</span>
          <img src="/assets/img/bx-home-alt.svg" alt="home icon">
        </button>
      </div>
      <div class="w-128 3xl:w-144 flipped-ltr">
        <h1 class="mb-2 3xl:mb-3 text-2xl 3xl:text-3xl">Set your Search Options</h1>
        <div class="mb-4 3xl:mb-5">
          <!--* FILTERS -->
          <app-filters></app-filters>
          <!--* CART -->
          <app-cart></app-cart>
        </div>
        <h1 class="mb-2 3xl:mb-3 text-2xl 3xl:text-3xl">Summary</h1>
        <!--* SUMMARY -->
        <div class="h-16 3xl:h-24 px-6 py-2 flex justify-between items-center bg-blue text-white rounded-2.5xl">
          <div class="flex">
            <h5 class="mr-3 flex items-center">Total Seats
              <span class="w-3 mx-1"><img src="/assets/img/chair-white-icon.svg" alt="white chair icon" class="w-3"></span>:
            </h5>
            <div class="w-16 h-8 px-4 flex justify-center items-center border rounded-full">
              <h5>{{ selectedSeats.length }}</h5>
              <!-- <input type="text" [(ngModel)]="selectedSeats.length" class="w-8 text-sm font-bold text-center bg-blue"> -->
            </div>
          </div>
          <div class="text-center">
            <h4 class="text-sm 3xl:text-base font-normal">Ticket Total:</h4>
            <h4>$ {{ totalSeatsPrice | number: '1.2-2' }}</h4>
          </div>
          <div>
            <!-- <button class="btn-white w-40 font-bold disabled:opacity-20" (click)="checkout()" [disabled]="selectedSeats.length < 15">Go to Checkout</button> -->
            <button class="btn-white w-40 font-bold disabled:opacity-20" (click)="checkout()"
                    [disabled]="selectedSeats.length < event.minTickets || selectedSeats.length > event.maxTickets">Go to Checkout
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--* SEATS DATA - Mobile -->
  <div *ngIf="isMobile" class="h-1/2 relative">
    <div (click)="toggleShowTabs()" class="mx-auto p-2 absolute -top-8 left-1/2 transform -translate-x-1/2">
      <img src="/assets/img/arrow-down-white-mobile-icon.svg" [ngClass]="!openTabs? 'transform rotate-180 duration-700': 'duration-700'" alt="Arrow down icon">
    </div>
    <!-- Tabs -->
    <div>
      <ul class="flex text-sm text-gray-400 border-gray-100">
        <li class="flex-auto">
          <button id="filters-tab" class="w-full px-4 py-3 font-bold border-b-3"
                  [ngClass]="{'text-black border-red': activeTab === 'filters'}"
                  (click)="activeTab = 'filters'">How many Tickets?</button>
        </li>
        <li class="flex-auto">
          <button id="cart-tab" class="w-full px-4 py-3 font-bold border-b-3"
                  [ngClass]="{'text-black border-blue': activeTab === 'cart'}"
                  (click)="activeTab = 'cart'">Your Seats</button>
        </li>
      </ul>
    </div>

    <!-- Tabs Content -->
    <div id="tab-content" class="bg-white overflow-y-auto">
      <div id="filters" class="h-full" [ngClass]="activeTab === 'filters'? 'block': 'hidden'">
        <!--* FILTERS -->
        <app-filters></app-filters>
      </div>
      <div id="cart" class="h-full" [ngClass]="activeTab === 'cart'? 'block': 'hidden'">
        <!--* CART -->
        <app-cart (checkoutFunc)="checkout()"></app-cart>
      </div>
    </div>
  </div>

  <!--* UPSELLS -->
  <div [hidden]="is3dOpen" id="upsells" class="absolute top-0 right-0">
    <app-upsells></app-upsells>
    <!-- <app-upsells [currentEvent]="event"></app-upsells> -->
  </div>

  <!--* TUTORIAL -->
  <div [hidden]="!isTutorialOpen" id="tutorial" class="w-full h-full absolute top-0 left-0 z-20">
    <app-tutorial></app-tutorial>
  </div>

</div>

<!--* DVM POPOVER -->
<div id="tooltip" class="dvmPopover p-2 z-10" role="tooltip">
<!-- <div id="tooltip" class="dvmPopover" role="tooltip" style="pointer-events: none;"> -->
  <div id="arrow" data-popper-arrow></div>
  <!-- Placement to insert the map popovers -->
  <div #dynamicPopover></div>
</div>

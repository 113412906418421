<!-- <div class="w-176 text-center rounded-2.5xl"> -->
  <div class="w-full text-center rounded-2.5xl">
    <!-- Header -->
    <div class="border-b border-gray-300" [ngClass]="isMobile? 'px-12 py-6': 'px-24 py-12'">
      <h1 [ngClass]="{'text-xl': isMobile}">
        <span class="flex flex-col items-center">
          <img src="/assets/img/logo.svg" alt="Chicago Cubs logo" class="h-20 mb-2">
          <span class="text-red">WELCOME!</span>
        </span>
      </h1>
    </div>
    <div [ngClass]="isMobile? 'px-12 py-6': 'px-24 py-12'">
      <!-- <h4 class="font-normal">{{ content }}</h4> -->
      <h4 class="font-normal">Would you like to view a quick tutorial before purchasing tickets?</h4>
      <div class="flex justify-center" [ngClass]="isMobile? 'mt-5': 'mt-12'">
        <button (click)="close()" class="btn-blue-reverse" [ngClass]="isMobile? 'mr-3': 'mr-6'">No</button>
        <button (click)="accept()" class="btn-blue">Yes</button>
      </div>
      <div *ngIf="isMobile" class="small info-help mt-3">
        <span>For questions or assistance,<br>please call <b>773-404-4242</b><br>or email <b>cubsgrouptickets@cubs.com</b></span>
      </div>
    </div>
  </div>

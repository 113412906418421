import { Component, OnInit } from '@angular/core';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { AvailabilityService, UtilitiesService } from 'src/app/shared/services';
import { GeneralAvailability } from "../../../shared/models";
import { BestAvailableService } from '../../services/best-available.service';
import { DvmService } from '../../services/dvm.service';
import { Popover3dviewService } from '../../services/popover-3dview.service';

@Component({
  selector: 'app-section-popover',
  templateUrl: './section-popover.component.html',
  styleUrls: ['./section-popover.component.scss']
})
export class SectionPopoverComponent implements OnInit {

  isMobile: boolean;

  sectionData: GeneralAvailability;
  tooltipData: { section: string, img: string };

  includedSectionForBA: boolean;

  constructor(private dvmService: DvmService,
              private availabilityService: AvailabilityService,
              private utilitiesService: UtilitiesService,
              private bestAvailableService: BestAvailableService,
              private popover3dview: Popover3dviewService) { }

  get priceRange() {
    let priceRange = this.sectionData.priceRange[0].value.toString(10);
    if (!this.sectionData) {
      return '';
    }
    if (this.sectionData.priceRange[0].value != this.sectionData.priceRange[1].value) {
      priceRange = `${this.sectionData.priceRange[0].value} - ${this.sectionData.priceRange[1].value}`
    }
    return priceRange;
  }

  get adaQuantity() {
    if (this.availabilityService.sectionsAvailability[this.sectionData.mmcID] && this.availabilityService.sectionsAvailability[this.sectionData.mmcID].availableQuantityAda) {
      return this.availabilityService.sectionsAvailability[this.sectionData.mmcID].availableQuantityAda;
    } else if (this.availabilityService.sectionsOnlyAdas[this.sectionData.mmcID] && this.availabilityService.sectionsOnlyAdas[this.sectionData.mmcID].availableQuantityAda) {
      return this.availabilityService.sectionsOnlyAdas[this.sectionData.mmcID].availableQuantityAda;
    }
    return 0;
  }

  ngOnInit(): void {
    // Device detection
    this.isMobile = this.utilitiesService.isMobile;

    this.includedSectionForBA = this.bestAvailableService.selectedSectionsForBA.includes(this.sectionData.mmcID);
    this.tooltipData = { section: this.sectionData.mmcID, img: '' };

    // this.viewer.getThumbnail(this.tooltipdata.section)
    let thumbSection = this.tooltipData.section;
    if (this.tooltipData.section === 'S_BLCHADA') {
      thumbSection = 'S_Bleacher'
    }
    this.dvmService.viewer.getThumbnail(thumbSection)
    .then((img: any) => {
      this.tooltipData.img = img.src;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  load3dView() {
    const tooltip: HTMLElement = document.querySelector('#tooltip');
    tooltip.removeAttribute('data-show');
    this.popover3dview.deleteTimer();
    this.dvmService.load3dView(this.sectionData.mmcID);
  }

  onEnter() {
    this.popover3dview.deleteTimer();
  }

  onLeave() {
    this.popover3dview.setTimer();
  }

  openSeatMap() {
    this.dvmService.loadMap(this.sectionData.mmcID);
    this.popover3dview.setTimer();
  }

  selectSectionForBA() {
    if (!this.includedSectionForBA) {
      this.bestAvailableService.selectSectionForBA(this.sectionData.mmcID);
    } else {
      this.bestAvailableService.unselectSectionForBA(this.sectionData.mmcID);
    }
    this.includedSectionForBA = this.bestAvailableService.selectedSectionsForBA.includes(this.sectionData.mmcID);
  }

}

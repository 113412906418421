import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[appNumbersInput]'
})
export class NumbersInputDirective {

  constructor(
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.elementRef.nativeElement.value = this.transform(this.elementRef.nativeElement.value);
  }

  transform(value: string) {
    if (!value) return "";
    return value.replace(/\D/g, ''); // Remove non-digits and trim
  }

  @HostListener("input", ["$event.target.value"])
  change() {
    this.elementRef.nativeElement.value = this.transform(this.elementRef.nativeElement.value);
  }

}

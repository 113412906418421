import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckoutComponent } from './checkout/checkout.component';
import { LoginComponent } from './login/login.component';
import { PaymentComponent } from './payment/payment.component';
import { SeatSelectionComponent } from './seat-selection/seat-selection.component';
import { EventDataResolver } from './shared/resolvers/event-data.resolver';
import { SummaryComponent } from './summary/summary.component';
import { EventLandingComponent } from './event-landing/event-landing.component';
import { EventLandingResolver } from './shared/resolvers/event-landing.resolver';
import { OpponentsResolver } from './shared/resolvers/opponents.resolver';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { RedirectPageComponent } from "./okta-integration/components/redirect-page/redirect-page.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'events'
  },
  {
    path: 'okta/redirect',
    component: RedirectPageComponent,
  },
  {
    path: '',
    // canActivate: [AuthGuard],
    // canActivateChild: [AuthGuard],
    children: [
      {
        path: 'events',
        component: EventLandingComponent,
        resolve: {
          eventList: EventLandingResolver,
          opponentList: OpponentsResolver
        }
      },
      {
        // path: 'seat-selection/:eventId',
        path: 'seat-selection',
        component: SeatSelectionComponent,
        resolve: {
          event: EventDataResolver,
        },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
      },
      {
        path: 'login',
        component: OktaCallbackComponent,
      },
      {
        path: 'checkout/:id', // <---- Transaction id
        component: CheckoutComponent
      },
      {
        path: 'payment/:id', // <---- Transaction id
        component: PaymentComponent
      },
      {
        path: 'summary/:id', // <---- Transaction id
        component: SummaryComponent
      }
    ]
  },
  {
    path: '**', // Unknown route protection
    redirectTo: 'events'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<!-- <div class="w-176 text-center rounded-2.5xl"> -->
<div class="w-full text-center rounded-2.5xl">
  <!-- Header -->
  <div class="border-b border-gray-300" [ngClass]="isMobile? 'px-12 py-6': 'px-24 py-12'">
    <h1 [ngClass]="{'text-xl': isMobile}" [innerHTML]="title"></h1>
  </div>
  <!-- <hr> -->
  <!-- Body -->
  <div [ngClass]="isMobile? 'px-12 py-6': 'px-24 py-12'">
    <!-- <h4 class="font-normal">{{ content }}</h4> -->
    <h4 class="font-normal" [innerHTML]="content"></h4>
    <div class="flex justify-center" [ngClass]="isMobile? 'mt-6': 'mt-12'">
      <button *ngIf="closeBtnName" (click)="close()" class="btn-blue-reverse" [ngClass]="isMobile? 'mr-3': 'mr-6'">{{ closeBtnName }}</button>
      <button *ngIf="acceptBtnName" (click)="accept()" class="btn-blue">{{ acceptBtnName }}</button>
    </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UpsellsService } from '../seat-selection/upsells/upsells.service';
import { ModalsService } from '../shared/modals/modals.service';
import { Seat, TransactionWrapper, Upsell } from '../shared/models';
import { SummaryLinkModel } from '../shared/models/summary-link.model';
import { UtilitiesService } from '../shared/services';
import { ConnectionService } from '../shared/services/connection.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

  isMobile: boolean;
  validTransaction: boolean;
  transactionId;
  transaction: TransactionWrapper;

  summaryLink: SummaryLinkModel;
  noImage: boolean = false;

  seats: Seat[];
  seatsByPrice: any[];
  seatsBySections;
  sortedSeatsBySectionArray;
  sortedSeatsBySectionAndRowArray;
  seatsForTable;

  // selectedPackage: any;

  get acquiredUpsells(): Upsell[] {
    return this.upsellsService.acquiredUpsells;
  }

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private connectionService: ConnectionService,
              private utilitiesService: UtilitiesService,
              private upsellsService: UpsellsService,
              private modalsService: ModalsService) {
                this.validTransaction = false;
               }

  ngOnInit(): void {
    // Device detection
    this.isMobile = this.utilitiesService.isMobile;

    this.transactionId = this.activatedRoute.snapshot.params['id'];
    this.connectionService.token = this.activatedRoute.snapshot.queryParams['token'];

    this.connectionService.getTransactionById(this.transactionId).subscribe({
      next: response => {
        console.log('TRANSACTION', response);
        this.transaction = response;
        let status = this.transaction.transaction.status
        if (status === 'CA') { // if transaction is already cancelled, give info customer and move him to events
          this.openCancelledTransactionModal()
        } else if (status === 'PH') { // if transaction status is pendin hold, move to checkout
          this.router.navigate(['/', 'checkout', this.transactionId], {queryParams: {'token': this.connectionService.token}});
        } else {
          this.validTransaction = true;
          this.seats = [];
          this.seatsByPrice = [];
          // this.upgradeSelected = null;
          const pricesHolder = {}
          // create structure for frontend
          for (const [mmcId, price] of Object.entries(this.transaction.transaction.seatsPrices)){
            let seat = this.utilitiesService.splittedSeatInfoFromId(mmcId) as Seat;
            seat.price = price.value;
            this.seats.push(seat);
            if(!pricesHolder[seat.price]) {
              pricesHolder[seat.price] = {'price': seat.price, 'quantity': 1};
            }else{
              pricesHolder[seat.price].quantity += 1;
            }
          }
          this.seatsByPrice = Object.values(pricesHolder) as [];
          // console.log('Seats checkout: ', this.seats);

          this.seatsBySections = this.utilitiesService.distributeSeatsBySection(this.seats);
          this.sortedSeatsBySectionArray = this.utilitiesService.sortSectionsBySeatsQuantity(this.seatsBySections);
          this.sortedSeatsBySectionAndRowArray = this.utilitiesService.addRowDsitribution(this.sortedSeatsBySectionArray);
          this.sortedSeatsBySectionAndRowArray.forEach(section => {
            section.rows = Object.values(section.rows);
          });
          this.seatsForTable = this.utilitiesService.addRangeSeats(this.sortedSeatsBySectionAndRowArray);
          console.log('Sorted Seats: ', this.seatsForTable);

          // Summary Links
          this.getSummaryLink(this.transaction.transaction.event.pvEventId);

          // Upsells
          this.upsellsService.upsells = this.transaction.transaction.event.upsells;
          this.upsellsService.checkCartSeatsForUpsellsList(this.seats.length);
        }
      },
      error: error => {
        console.error(error);
        const modalData = {
          title: "ERROR",
          content: "An Error occurred while trying to get Transaction data.",
          // closeBtnName: 'CLOSE',
          acceptBtnName: 'CLOSE',
          // acceptFunction: () => {this.goTo('checkout')}
        };
        // If there's a custom api error.
        if (error.error.hasOwnProperty('code')) {
          modalData.content = error.error.message;
        };
        this.modalsService.openModal(modalData);
      }
    });
  }

  getSummaryLink(eventId: number) {
    this.connectionService.getSummaryLink(eventId).subscribe({
      next: response => {
        console.log('Summary Link: ', response);
        this.summaryLink = response as SummaryLinkModel;
        if (this.summaryLink.url_image === "") {
          console.log('hola');
          this.noImage = true;
        }
      },
      error: error => console.error(error)
    });
  }

  openCancelledTransactionModal() {
    const modalData = {
      title: "Transaction Cancelled",
      content: "The transaction has been cancelled.",
      // closeBtnName: 'CLOSE',
      acceptBtnName: 'CLOSE',
      acceptFunction: () => { this.router.navigate(['/events']) }
    };
    this.modalsService.openModal(modalData);
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConnectionService } from '../shared/services/connection.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationModel, SaleEvent } from '../shared/models/';
import { filter, map, Observable, Subscription } from 'rxjs';
import { UtilitiesService } from '../shared/services';
import { OktaAuthStateService } from '@okta/okta-angular';
import { AuthState } from '@okta/okta-auth-js';

@Component({
  selector: 'app-event-landing',
  templateUrl: './event-landing.component.html',
  styleUrls: ['./event-landing.component.scss']
})
export class EventLandingComponent implements OnInit, OnDestroy {

  isAuthenticated$!: Observable<boolean>
  events: PaginationModel<SaleEvent>;
  eventsBackup: Array<SaleEvent>
  dayPerMonth: Array<number> = [];
  formSelects: {
    month: string;
    day: string;
    time: string;
    opponent: string;
  }
  opponentList: Array<{ description: string; }>
  subscriptions$: Array<Subscription>
  currentPage: number = 1
  totalItemsInDb: number;
  itemsPerPage: number;
  isMobile: boolean;

  constructor(private connection: ConnectionService, private activatedRoute: ActivatedRoute,
    private utilitiesService: UtilitiesService, private router: Router, private _oktaStateService: OktaAuthStateService) {
    this.subscriptions$ = [];
    this.formSelects = {
      month: 'none',
      day: 'none',
      time: 'none',
      opponent: 'none',
    }
   }

  ngOnInit(): void {
    const queryparams$ = this.activatedRoute.queryParams.subscribe(params => {
      if(params['page']){
        this.currentPage = params['page'];
        let meta = this.activatedRoute.snapshot.data['eventList'].meta
        if (this.currentPage && meta.current_page !== this.currentPage) {
          this.onSubmit()
        }
      }
    });
    this.subscriptions$.push(queryparams$);
    this.isMobile = this.utilitiesService.isMobile;
    this.events = this.activatedRoute.snapshot.data['eventList'];
    this.itemsPerPage = this.events.meta.per_page
    this.totalItemsInDb = this.events.meta.total
    this.opponentList = this.activatedRoute.snapshot.data['opponentList'];
    this.eventsBackup = JSON.parse(JSON.stringify(this.events))
    this._oktaStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    ).subscribe({
      next: (isAuth) => {
        console.log(isAuth);
      }
    })
  }

  ngOnDestroy(): void {
    this.subscriptions$.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  getSelection($event: number): void {
    this.router.navigate(['/seat-selection'], { queryParams: { event: $event }});
  }

  onSubmitFilters(): void {
    this.currentPage = 1
    this.onSubmit();
  }

  onSubmit() {
    let sub$ = this.connection.getEventsList(this.currentPage, this.formSelects).subscribe({
      next: (response) => {
        this.events = response as PaginationModel<SaleEvent>;
        this.itemsPerPage = this.events.meta.per_page
        this.totalItemsInDb = this.events.meta.total
      },
      error: () => {}
    })
    this.subscriptions$.push(sub$);
  }

  onChangePage($event: number) {
    console.log("coña??", $event);
  }

  resetForm() {
    this.formSelects = {
      opponent: 'none',
      day: 'none',
      month: 'none',
      time: 'none'
    }
    this.dayPerMonth.length = 0
    this.events = JSON.parse(JSON.stringify(this.eventsBackup))
    this.itemsPerPage = this.events.meta.per_page
    this.totalItemsInDb = this.events.meta.total
    this.refreshPage();
    // let currenturl = '/private/' + this.activatedRoute.snapshot.routeConfig.path;
    // this.router.navigate([currenturl], { queryParams: { page: 1 } });
  }

  calculateDayPerMonth($event): void {
    let value = $event.target.value
    if (value === 'none') {
      this.dayPerMonth.length =0;
      return;
    }
    let days = this.daysInMonth(value);
    this.dayPerMonth = Array.from(Array(days).keys());
  }

  private daysInMonth(month: string): number {
    let year = new Date().getFullYear()
    let totalOfDay = new Date(year, parseInt(month, 10), 0);
    return totalOfDay.getDate()
  }

  refreshPage(): void {
    // save current route first
    const currentRoute = this.router.url;

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute], { queryParams: { page: null }, queryParamsHandling: 'merge' }); // navigate to same route
    });
  }

}

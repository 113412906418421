import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of, catchError, map } from 'rxjs';
import { ConnectionService } from '../services/connection.service';

@Injectable({
  providedIn: 'root'
})
export class EventLandingResolver implements Resolve<any> {
  constructor(private connection: ConnectionService){}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // let pageText = route.queryParamMap.get('page');
    // let page = parseInt(pageText, 10);
    // if (!page)
    //   page = 1
    return this.connection.getEventsList(1, { month: 'none', day: 'none', opponent: 'none'}).pipe(
      map((list) => list),
      catchError((error) => {
        console.log(error);
        return of(false)
      })
    )
  }
}

import { Injectable } from "@angular/core";
import { DvmService } from "./dvm.service";

@Injectable({
    providedIn: 'root'
})

/* ISOLATED SEAT HANDLING */

export class IsolatedSeatsService {
    isolatedSeatsActive = false;
    lastIsolatedSeats;

    constructor(private dvmService: DvmService) { }

    public checkIsolatedSeats(seats) {
        const isolatedSeats = [];
        for (let i = 0; i < seats.length; ++i) {
            let seat = seats[i];
            if (typeof seat === 'string') {
                seat = this.dvmService.viewer.getNodeById(seat);
            }
            if (seat && seat.row) {
                const row = seat.row;
                const index = row.indexOf(seat.id);
                const right_id1 = row[index + 1];
                const right_id2 = row[index + 2];
                const left_id1 = row[index - 1];
                const left_id2 = row[index - 2];

                if (this.isAvailable(right_id1)) { // Si hay uno disponible a la derecha
                    if (!this.isAvailable(right_id2)) { // Si no hay uno disponible 2 a la derecha
                        isolatedSeats.push(right_id1); // El de la derecha está aislado
                    }
                }
                if (this.isAvailable(left_id1)) { // Si hay uno disponible a la izquierda
                    if (!this.isAvailable(left_id2)) { // Si no hay uno disponible 2 a la izquierda
                        isolatedSeats.push(left_id1); // El de la izquierda está aislado
                    }
                }
            } else {
                console.error('Seat or Row not matching request: ', seat);
            }
        }
        return isolatedSeats;
    }

    private isAvailable(id) {
        if (id) {
            const element = this.dvmService.viewer.getNodeById(id);
            return element.state === "available";
        }
        return false; // Si no existe, no es available
    }

}
<div id="filters"[ngClass]="isMobile? 'h-full': 'mb-3 3xl:mb-4 bg-white rounded-2.5xl overflow-hidden'">
  <!-- Overlay test for Tutorial -->
  <div *ngIf="tutorialOn" class="w-full h-full z-20 absolute top-0 left-0 right-0 bottom-0 backdrop-filter backdrop-blur-xl"></div>
  <!-- <div class="h-full z-20 absolute top-0 left-0 right-0 bottom-0 bg-black filter blur-xl"></div> -->

  <!-- Header -->
  <div id="filters-header" class="w-full px-6 py-2 relative flex bg-blue text-white" [ngClass]="[isMobile? 'h-12': 'h-14 3xl:h-16 justify-between items-center', {'backdrop-filter-none backdrop-blur-none z-20': tutorialOn}]">
    <div *ngIf="!isMobile" class="mr-5">
      <h5>How Many Tickets?</h5>
      <p>Minimum of {{ minSeatsLimit }}.</p>
    </div>
    <div class="flex" [ngClass]="{'w-full justify-between items-center': isMobile}">
      <h5 *ngIf="!isMobile" class="mr-3 flex items-center">
        <span>Select</span>
        <span class="w-3 mx-1"><img src="/assets/img/chair-white-icon.svg" alt="white chair icon" class="w-3"></span>:
      </h5>

      <!-- Mobile -->
      <div *ngIf="isMobile" class="mr-3">
        <h5 class="flex items-center">
          <span>Number of Seats</span>
          <span class="w-3 mx-1"><img src="/assets/img/chair-white-icon.svg" alt="white chair icon" class="w-3"></span>:
        </h5>
        <p>Minimum of {{ minSeatsLimit }}.</p>
      </div>

      <div class="flex">
        <button class="btn-icon bg-red" [ngClass]="isMobile? 'w-7 h-7': 'w-8 h-8'" (click)="oneSeatLess()">
          <img src="/assets/img/dash-symbol.svg" alt="dash symbol">
        </button>
        <div class="w-16 px-4 mx-2 flex justify-center items-center border rounded-full">
          <!-- <input id="input-seats" type="text" [(ngModel)]="numberSeats" (ngModelChange)="seatsDataChanged($event)" (focusout)="onFocusOut()" class="w-8 text-sm font-bold text-center bg-blue"> -->
          <input id="input-seats" type="text" appNumbersInput [(ngModel)]="numberSeats" (focusout)="onFocusOut()" class="w-8 font-bold text-center bg-blue" [ngClass]="isMobile? 'text-xs': 'text-sm'">
        </div>
        <button class="btn-icon bg-red" [ngClass]="isMobile? 'w-7 h-7': 'w-8 h-8'" (click)="oneSeatMore()">
          <img src="/assets/img/plus-symbol.svg" alt="plus symbol">
        </button>
      </div>
    </div>
  </div>

  <!-- Body -->
  <div id="filters-body" class="w-full text-black" [ngClass]="{'overflow-y-auto': isMobile}">
    <div class="px-6 py-2 border-b border-gray-300" [ngClass]="isMobile? '': 'h-12 3xl:h-14 flex justify-between items-center'">
      <h5 class="mr-3 font-normal" [ngClass]="{'my-2 text-xs': isMobile}">What's your price range per ticket?</h5>
      <div [ngClass]="isMobile? 'w-full p-2': 'w-44'"> <!-- text-gray-600 -->
        <!-- <h5 class="mr-2 font-bold">${{ minSeatPrice }}</h5> -->
        <!--* Multi Range Slider -->
        <div class="custom-slider w-full">
          <ngx-slider [(value)]="minValue"
                      [(highValue)]="maxValue"
                      [options]="sliderOptions"
                      (userChangeEnd)="onUserChangeEnd($event)">
          </ngx-slider>
          <!-- <app-npn-slider></app-npn-slider> -->
          <!-- <app-multi-range-slider></app-multi-range-slider> -->
        </div>
        <!-- <h5 class="ml-1 font-bold">${{ maxSeatPrice }}</h5> -->
      </div>
    </div>

    <div class="px-6 py-2 text-blue flex justify-between items-center border-b border-gray-300" [ngClass]="isMobile? 'h-14': 'h-12 3xl:h-14'">
      <div class="mr-3">
        <h5 class="font-normal" [ngClass]="{'text-xs': isMobile}">Do you need accessible seating?
          <span class="ml-1 inline-block align-middle"><img src="/assets/img/accessible-icon.svg" alt="accessible chair icon"></span>
        </h5>
      </div>
      <!-- Boton ¿Quieren adas?-->
      <button *ngIf="!needADA" class="btn-blue-reverse h-9 flex justify-center items-center" (click)="activeAda()">
        <span *ngIf="!isMobile">Yes, add accessible seating</span>
        <span *ngIf="isMobile">Yes, show</span>
      </button>
      <!-- Selector de tickets Ada -->
      <div *ngIf="needADA" class="flex">
        <h5 *ngIf="!isMobile" class="mr-3 font-bold flex items-center">Select:
          <!-- <span class="w-3 mx-1"><img src="/assets/img/accessible-icon.svg" alt="accessible chair icon" class="w-3"></span> -->
        </h5>
        <!-- <img src="/assets/img/close-symbol-blue.svg" alt="close symbol" id="close-symbol"> -->
        <button class="btn-icon bg-blue" [ngClass]="isMobile? 'w-7 h-7': 'w-8 h-8'" (click)="oneAccSeatLess()">
          <img src="/assets/img/dash-symbol.svg" alt="dash symbol">
        </button>
        <div class="w-16 px-4 mx-2 flex justify-center items-center border border-gray-300 rounded-full">
          <!-- <h5>{{ numberAccessibleSeats }}</h5> -->
          <input type="text" [(ngModel)]="numberAccessibleSeats" (focusout)="onFocusOut('ada')" class="w-8 text-sm font-bold text-center text-black bg-white" [ngClass]="isMobile? 'text-xs': 'text-sm'">
        </div>
        <button class="btn-icon bg-blue" [ngClass]="isMobile? 'w-7 h-7': 'w-8 h-8'" (click)="oneAccSeatMore()">
          <img src="/assets/img/plus-symbol.svg" alt="plus symbol">
        </button>
        <button class="btn-icon bg-red ml-2 rotation" [ngClass]="isMobile? 'w-7 h-7': 'w-8 h-8'" (click)="closeAdaSelector()">
          <img src="/assets/img/plus-symbol.svg" alt="close symbol">
        </button>
      </div>
    </div>
  </div>

  <!-- footer -->
  <div id="filters-footer" class="px-6 py-2 flex" [ngClass]="isMobile? 'justify-center flex-wrap': 'h-14 3xl:h-16 justify-between items-center'">
    <h5 class="font-normal ada-info-text" [ngClass]="{'mb-2 text-xs': isMobile}">Accessible seats included in ticket total</h5>
    <button class="btn-blue h-9"
            [disabled]="bestAvailableIsAvailable()"
            [tooltipEnable]="bestAvailableIsAvailable()"
            [tooltip]="'Try again on the overview map'"
            triggers="pointerenter:pointerout"
            (click)="addBestSeats()">
      Add Best Seats
    </button>
  </div>
</div>

<div *ngIf="!isMobile" id="event" class="flex flex-row justify-around items-center">
  <div class="box first flex flex-row w-full items-center justify-center">
    <div class="mr-3" *ngIf="event.urlImage">
      <img [src]="event.meta.image" alt="event image">
    </div>
    <div class="flex flex-col" style="width: 240px;">
      <span><b>{{ event.description }}</b></span>
      <span *ngIf="event.extraText" class="text-sm">{{ event.extraText }}</span>
    </div>
  </div>
  <div class="box middle">
    <span>{{ event.date | date }}</span><br>
    <span>{{ event.date | date:'hh:mm a'}}</span>
  </div>
  <div class="box last">
    <button type="button" class="select-button" (click)="notifyParent(event.pvEventId)">Buy Tickets</button>
  </div>
</div>

<div *ngIf="isMobile" id="event-mobile" class="flex flex-col w-80 mx-auto text-center">
  <div class="rival">
    <b>{{ event.description }}</b>
  </div>
  <div class="date">
    <span>{{ event.date | date }}</span><br>
    <span>{{ event.date | date:'hh:ss a'}}</span>
  </div>
  <div class="buy-ticket">
    <button type="button" class="select-button-mobile" (click)="notifyParent(event.pvEventId)">Buy Tickets</button>
  </div>
</div>

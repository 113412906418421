import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ModalData } from '../models';
import { GaModalComponent } from './ga-modal/ga-modal.component';
import { ModalsComponent } from './modals.component';
import { TutorialWelcomeModalComponent } from './tutorial-welcome-modal/tutorial-welcome-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalsService {

  bsModalRef?: BsModalRef;

  constructor(private modalService: BsModalService) { }

  openModal({...modalData}: ModalData) {
    const initialState: ModalOptions = {
      initialState: modalData,
      // initialState: {
      //   title,
      //   content: message,
      //   closeBtnName,
      //   closeFunction,
      //   acceptBtnName,
      //   acceptFunction
      // },
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered modal-lg'
      // class: 'modal-lg'
    }
    this.bsModalRef = this.modalService.show(ModalsComponent, initialState);
    // this.bsModalRef.content.closeBtnName = 'CLOSE';
  }

  openGaModal({...modalData}: ModalData) {
    const initialState: ModalOptions = {
      initialState: modalData,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered modal-lg'
    }
    this.bsModalRef = this.modalService.show(GaModalComponent, initialState);
  }

  openTutorialWelcomeModal({...modalData}) {
    const initialState: ModalOptions = {
      initialState: modalData,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered modal-lg'
    }
    this.bsModalRef = this.modalService.show(TutorialWelcomeModalComponent, initialState);
  }
}

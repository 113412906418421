<div id="header" class="w-full fixed flex items-center justify-between text-white" [ngClass]="[isMobile? 'h-12 px-2 py-1':'h-16 px-3 py-2', showLegend? 'bg-black': 'bg-blue-dark']">

  <!-- LOGO & CLUB NAME -->
  <div *ngIf="!isMobile" class="mr-6 flex items-center">
    <img src="/assets/img/logo.svg" alt="Chicago Cubs logo" class="h-12">
    <div class="h-10 flex items-center">
      <h3 class="min-w-max ml-3">Chicago Cubs</h3>
      <ng-container *ngIf="!landing">
        <div class="h-full mx-5 border-l border-current"></div>
        <h5><span class="mr-1 font-normal">Event:</span> {{ event?.description }}</h5>
        <div class="h-full mx-5 border-l border-current"></div>
        <h5><span class="mr-1 font-normal"></span> {{ event?.date | date:'medium' }}</h5>
      </ng-container>
    </div>
  </div>
  <!-- Mobile -->
  <div *ngIf="isMobile" class="mr-6 flex items-center">
    <div *ngIf="backBtn === 'payment' || backBtn === 'summary'">
      <button class="btn-icon bg-transparent" (click)="goBack(backBtn)">
        <img src="/assets/img/arrow-left-white-mobile-icon.svg" alt="Arrow left white icon">
      </button>
    </div>
    <div *ngIf="isSectionMap || is3dOpen">
      <button class="btn-icon bg-transparent" (click)="goBackToTopview()">
        <img src="/assets/img/arrow-left-white-mobile-icon.svg" alt="Arrow left white icon">
      </button>
    </div>
    <img src="/assets/img/logo.svg" alt="Chicago Cubs logo" class="h-10">
    <h5 class="min-w-max ml-2">Chicago Cubs</h5>
  </div>

  <!-- SEAT-SELECTION -->
  <div *ngIf="!isMobile && showLegend && !is3dOpen" class="ml-6 flex items-center">
    <div id="legend" class="flex">
      <P class="mr-3">Legend:</P>
      <div class="mr-3 flex items-center">
        <div class="w-2 h-2 mx-2 bg-{{availableColor}} rounded-full"></div>
        <p>Available</p>
      </div>
      <div class="mr-3 flex items-center">
        <div class="w-2 h-2 mx-2 bg-{{mySeatsColor}} rounded-full"></div>
        <p>My seats</p>
      </div>
      <div *ngIf="isTopview" class="mr-3 flex items-center">
        <div class="w-2 h-2 mx-2 bg-{{selectedColor}} rounded-full"></div>
        <p>Selected</p>
      </div>
      <div *ngIf="!isTopview && isIsolatedActive" class="mr-3 flex items-center">
        <div class="w-2 h-2 mx-2 bg-{{isolatedColor}} rounded-full"></div>
        <p>Isolated</p>
      </div>
    </div>
    <div class="h-8 mx-3 border-l border-gray-600"></div>
    <button (click)="openTutorialQuestion()">
      <img src="/assets/img/information-icon.svg" alt="Information icon" class="mx-3">
    </button>
  </div>
  <!-- Mobile -->
  <div *ngIf="isMobile && showLegend && !is3dOpen" class="flex items-center">
    <button (click)="openLegend()" class="btn-white-reverse w-min h-8 align-middle">Legend</button>
    <button (click)="openTutorialQuestion()">
      <img src="/assets/img/information-icon.svg" alt="Information icon" class="ml-2">
    </button>
  </div>

</div>

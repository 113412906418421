import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DvmService } from 'src/app/digital-venue/services/dvm.service';
import { SeatManagementService } from 'src/app/digital-venue/services/seat-management.service';
import { Seat, SeatsBySection } from '../../shared/models';
import { AvailabilityService, CartService, UtilitiesService } from '../../shared/services';
import { UpsellsService } from '../upsells/upsells.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  isMobile: boolean;
  @Output() checkoutFunc = new EventEmitter<any>();

  Object = Object;
  sectionsListOpen: string[] = [];

  get seats(): Seat[] {
    // console.log('selectedSeats: ', this.cartService.selectedSeats);
    return Object.values(this.cartService.selectedSeats);
  };
  get totalSeatsPrice() {
    return this.cartService.selectedSeatsTotalPrice;
  }

  minSeatsLimit: number;
  maxSeatsLimit: number;

  seatsBySection: any[];
  // get seatsBySection(): SeatsBySection[] {
  //   console.log('selected Seats by Section: ', this.cartService.sortedSelectedSeatsBySectionArray[0].seats);
  //   return Object.values(this.cartService.sortedSelectedSeatsBySectionArray);
  // };

  constructor(private cartService: CartService,
              private dvmService: DvmService,
              private availabilityService: AvailabilityService,
              private utilitiesService: UtilitiesService,
              private upsellsService: UpsellsService,
              private seatManagementService: SeatManagementService) { }

  ngOnInit(): void {
    // Device detection
    this.isMobile = this.utilitiesService.isMobile;

    this.minSeatsLimit = this.availabilityService.minSeatsLimit;
    this.maxSeatsLimit = this.availabilityService.maxSeatsLimit;

    this.cartService.sortedSelectedSeatsBySectionArray$.subscribe({
      next: response => {
        // console.log(response);
        let sortedSeatsBySectionAndRowArray = this.utilitiesService.addRowDsitribution(response);
        sortedSeatsBySectionAndRowArray.forEach(section => {
          section.rows = Object.values(section.rows);
          section.rows.forEach(row => {
            row.seats = Object.values(row.seats).sort((a: Seat, b: Seat) => {
              return +a.seat - +b.seat;
            });
          });
        });
        this.seatsBySection = sortedSeatsBySectionAndRowArray;
        // console.log('Seats By Section: ', this.seatsBySection);
      },
      error: error => console.error()
    });

    //* UPSELLS
    // Setting initial seats for Upsells to show next benefits list
    console.log('this.minSeatsLimit', this.minSeatsLimit);
    setTimeout(() => {
      this.upsellsService.totalCartAndFiltersSeats$.next(this.minSeatsLimit);
      this.upsellsService.checkCartSeatsForUpsellsList(this.minSeatsLimit);
      // this.upsellsService.totalSeats.next(this.seats.length);
    }, 300);
  }

  checkoutMob() {
    // console.log('check');
    this.checkoutFunc.emit();
  }

  removeSectionSeatsFromSelectedSeats(section) {
    this.seatManagementService.unselectSelectedSeats(Object.keys(section.seats));
  }

  removeOneFromSelectedSeats(seat: Seat) {
    this.seatManagementService.unselectSelectedSeats([seat.id]);
  }

  removeAllFromSelectedSeats() {
    this.seatManagementService.unselectAllSelectedSeats();
  }

  goToSeatmap(sectionId) {
    // console.log('Section map ID: ', sectionId);
    this.dvmService.loadMap(sectionId);
  }

  isAda(seatId) {
    if (seatId && typeof seatId === 'string') {
      const sectionId = seatId.split('-')[0];
      const adaSeats = Object.values(this.availabilityService.seatsAvailability[sectionId]).filter(a => a.isAda).map(a => a.mmcID);
      return adaSeats.includes(seatId);
    }
    return false;
  }

  toggleSectionList(sectionId: string, element?: HTMLElement) {
    if (this.sectionsListOpen.includes(sectionId)) {
      this.sectionsListOpen = this.sectionsListOpen.filter(item => item !== sectionId);
      console.log('Remove List Open: ', this.sectionsListOpen);
      if(element){
        element.classList.replace('rotate', 'no-rotate');
      }
    } else {
      this.sectionsListOpen.push(sectionId);
      console.log('Add List Open: ', this.sectionsListOpen);
      if(element){
        element.classList.replace('no-rotate', 'rotate')
      }
    }
  }

  load3dView(seatId) {
    let panoId = seatId;
    if (seatId.includes('S_Bleacher') || seatId.includes('S_BLCHADA')) {
      panoId = 'S_Bleacher'
    }
    if (this.dvmService.viewer3d && this.dvmService.viewer3d.getViewId() === panoId) {
      return;
    }
    this.dvmService.load3dView(panoId);
  }

  isGASection(sectionId) {
    return  sectionId == 'S_BLCHADA' || sectionId==='S_Bleacher';
  }

}

<!-- <div *ngIf="!isMobile" id="footer" class="flex w-full justify-center items-center text-sm" [ngClass]="[switchColor ? 'bg-black' : 'bg-blue-dark']" >
  <span>For questions or assistance, please call <b>773-404-4242</b> or email <b>cubsgrouptickets@cubs.com</b></span>
</div> -->
<div
  *ngIf="!isMobile"
  id="footer"
  class="flex w-full items-center text-sm"
  [ngClass]="[switchColor ? 'bg-black' : 'bg-main-dark']">
  <div class="flex w-full justify-between items-center">
    <div class="flex-1 text-center">
      For questions or assistance, please call
      <b>773-404-4242</b> or email
      <<b>cubsgrouptickets@cubs.com</b>
    </div>
    <div class="flex-initial">
      <span class="whitespace-nowrap mr-5 text-xs"
        >Version: {{ appPackage.version }}</span
      >
    </div>
  </div>
</div>

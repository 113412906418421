import { MapViewerBestNodesAlgorithm, MapViewerGetBestNodesInput } from '@3ddv/dvm-internal';
import { Injectable } from '@angular/core';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { AvailabilityService, CartService } from 'src/app/shared/services';
import { DvmService } from './dvm.service';
import { SeatManagementService } from './seat-management.service';

@Injectable({
  providedIn: 'root'
})
export class BestAvailableService {

  viewer: any;
  selectedSectionsForBA: string[] = [];
  bestSeats: any[];
  bestSeatsIds: string[];

  constructor(private dvmService: DvmService,
              private seatManagementService: SeatManagementService,
              private availabilityService: AvailabilityService,
              private cartService: CartService,
              private modalsService: ModalsService) {
    this.dvmService.viewerSubject.subscribe({
      next: response => this.viewer = response,
      error: error => {
        console.error(error);
        const modalData = {
          title: "ERROR",
          content: "An Error occurred while trying to get the Viewer.",
          acceptBtnName: 'CLOSE',
        };
        this.modalsService.openModal(modalData);
      }
    });
  }

  selectSectionForBA(nodeId: string) {
    // Select Section on Viewer
    let success = this.viewer.select([nodeId]);
    // console.log('Success SELECT Section? ', success);
    this.selectedSectionsForBA = this.viewer.getNodesByState('section', 'selected').map(node => {
      // console.log(node.id);
      return node = node.id;  // If your logic inside map() has more than one code line (using "{}"), you have to use a return, otherwise returns undefined
    });
  }

  unselectSectionForBA(nodeId: string) {
    // Select Section on Viewer
    let success = this.viewer.unselect([nodeId]);
    this.selectedSectionsForBA = this.viewer.getNodesByState('section', 'selected').map(node => node = node.id);
  }

  selectAccessibleSeats(number, preferredSections) {
    const accessibleSections = [];
    let seatsToSelect = number;
    Object.entries(this.availabilityService.seatsAvailability).forEach(([section, seatsAvailability]) => {
      if (seatsToSelect <= 0) return;
      if (preferredSections && !preferredSections.includes(section)) return;

      const consecutiveGroupsByCount = {
        even: [],
        odd: []
      };

      const adaInSection = Object.values(seatsAvailability).filter(a => a.isAda && !this.cartService.selectedSeats[a.mmcID]).map(a => a.mmcID);
      const adaInSectionCount = adaInSection.length;

      if (adaInSectionCount) {
        // Utilizamos la Row info para calcular las sillas contiguas
        const adaRow = this.dvmService.viewer.getNodeById(adaInSection[0])['row'];
        // Filtramos de la Row info las sillas que sabemos que son ADA
        const adaRowFiltered = adaRow.filter(a => {
          const x = this.dvmService.viewer.getNodeById(a);
          return x['tag'] === 'ada' || x['tag'] === 'adacomp';
        });

        // Ordenamos nuestra availability según el orden de la Row info
        adaInSection.sort((a, b) => {
          const ia = adaRowFiltered.indexOf(a);
          const ib = adaRowFiltered.indexOf(b);
          if (ia < 0) return 1;
          if (ib < 0) return -1;
          return ia - ib;
        })

        // Buscamos los grupos consecutivos y los separamos
        const consecutiveGroups = [];
        let lastGroup = -1;
        adaInSection.forEach(a => {
          const aNum = adaRowFiltered.indexOf(a);
          const lastItem = adaRowFiltered[aNum - 1];
          console.log(lastItem);

          if (!(lastGroup > -1 && consecutiveGroups[lastGroup] && consecutiveGroups[lastGroup].includes(lastItem))) {
            lastGroup = lastGroup + 1;
            consecutiveGroups[lastGroup] = [];
          }
          consecutiveGroups[lastGroup].push(a);
        });

        // Dividimos los grupos consecutivos por pares e impares
        consecutiveGroups.forEach(a => {
          if (a.length % 2) {
            consecutiveGroupsByCount.odd = consecutiveGroupsByCount.odd.concat(a);
          } else {
            consecutiveGroupsByCount.even = consecutiveGroupsByCount.even.concat(a);
          }
        })

        // Los grupos impares se pueden convertir en grupos pares restando una de las sillas
        // El resultado es una array de todas las sillas que dan grupos pares,
        // mas una silla separada en caso que haya un recuento impar
        if (consecutiveGroupsByCount.odd.length > 1) {
          const tmp = consecutiveGroupsByCount.odd[0];
          consecutiveGroupsByCount.odd.splice(tmp);
          consecutiveGroupsByCount.even = consecutiveGroupsByCount.even.concat(consecutiveGroupsByCount.odd);
          consecutiveGroupsByCount.odd = [tmp];
        }

      }

      // Si tenemos ADA en este sector
      if (adaInSectionCount > 0) {
        // Si tenemos una selección impar y tenemos sillas en grupos impares, primero seleccioanmos esto
        if (seatsToSelect % 2 && consecutiveGroupsByCount.odd.length) {
          this.seatManagementService.selectSeatsForCart([consecutiveGroupsByCount.odd[0]]);
          seatsToSelect = seatsToSelect - 1;
        }
        // Si quedan sillas por seleccionar...
        if (seatsToSelect > 0) {
          // Seleccionamos el resto, normalmente grupos pares
          consecutiveGroupsByCount.even.forEach(adaSeat => {
            if (seatsToSelect <= 0) return;
            this.seatManagementService.selectSeatsForCart([adaSeat]);
            seatsToSelect = seatsToSelect - 1;
          });
        }

        accessibleSections.push(section);
      } else {
        console.warn('This section has no ADA availability: ', section);
      }
    });

    console.log(accessibleSections);
    console.log(seatsToSelect);
    console.log(this.cartService.selectedSeats);

    if (seatsToSelect > 0 && preferredSections != null) {
      this.selectAccessibleSeats(seatsToSelect, null);
    } else if (seatsToSelect > 0) {
      console.warn('NO Best Nodes');
      const modalData = {
        title: "We are sorry!",
        content: "We could not find results matching your Best Seats selection. Please, try again with a different selection.",
        acceptBtnName: 'OK'
      };
      this.seatManagementService.unselectAllSelectedSeats();
      this.modalsService.openModal(modalData);
    }
    return accessibleSections;
  }

  getBestXSeats(nSeats: number, accessibleSeats?: number) {
    console.log('B.A. nSeats: ', nSeats);
    console.log('Accessible Seats', accessibleSeats);

    const input: MapViewerGetBestNodesInput = {
      type: 'seat',
      quantity: nSeats,
      algorithm: MapViewerBestNodesAlgorithm.NEIGHBORS,
      filter_single_nodes: true,
      // parent: ''
      // subset: []
    };

    // Request Availability
    let avSeatsForBA: string[] = [];
    let sectionsSelection = [];
    if (this.selectedSectionsForBA.length) {
      sectionsSelection = this.selectedSectionsForBA;
      // todo GA change quitar los ga del best available
    } else {
      sectionsSelection = this.dvmService.viewer.getNodesByState('section', 'available').map(a => a.id);
    }
    const sectionsHolder = []
    for (const section of sectionsSelection.values()){
      if (!this.dvmService.GA_sectors.includes(section) && !this.dvmService.GA_ada.includes(section)){
        sectionsHolder.push(section)
      }
    }
    sectionsSelection = sectionsHolder;
    this.availabilityService.getSeatAvailability(sectionsSelection).subscribe({
      next: availability => {

        this.dvmService.viewer.setAvailability('seat', availability);

        if (input.quantity > 0) {
          // if the user has current seats, they must be removed from availability
          let currentSeats = Object.keys(this.cartService.selectedSeats);
          // Remove ADA seats from the subset
          const adaSeats = this.viewer.getNodesByTag('seat', 'ada').map(a => a.id).concat(this.viewer.getNodesByTag('seat', 'adacomp').map(a => a.id));

          if (accessibleSeats) {
            const result = { ...this.availabilityService.sectionsAvailability, ...this.availabilityService.sectionsOnlyAdas }
            avSeatsForBA = availability.filter(a => {
              return result[a.split('-')[0]].availableQuantityAda > 0
            });
          } else {
            avSeatsForBA = availability;
          }

          let availabilityExcludingAdaAndSelectedSeats = avSeatsForBA.filter(x => !adaSeats.includes(x)).filter(x => !currentSeats.includes(x));
          avSeatsForBA = availabilityExcludingAdaAndSelectedSeats;
          input.subset = avSeatsForBA;

          this.viewer.best_nodes.getBestNodes(input).then(nodes => {
            // console.log('Best Nodes: ', nodes);
            if (nodes.length) {
              const nodeIds = nodes.map(node => node = node.id);
              this.bestSeatsIds = nodeIds;
              // console.log('Best Nodes IDs: ', nodeIds);
              //todo From Seat Management
              this.seatManagementService.selectSeatsForCart(nodeIds);

              let selectedSections = [...new Set(this.bestSeatsIds.map(a => a.split('-')[0]))]

              // ADA Selection flow
              if (accessibleSeats > 0) {
                const accessibleSections = this.selectAccessibleSeats(accessibleSeats, selectedSections);
                // Change sections eligible for Best Nodes
                // if (accessibleSections.length) {
                //   accessibleSections.forEach(section => {
                //     avSeatsForBA = avSeatsForBA.concat(Object.keys(this.availabilityService.seatsAvailability[section]));
                //   });
                // }
              }

              // Load Section map and goTo where selected seats are
              const mostSelectedSeatsSectionId = this.cartService.sortedSelectedSeatsBySectionArray[0].id;
              this.dvmService.loadMap(mostSelectedSeatsSectionId);
            } else {
              console.warn('NO Best Nodes');
              const modalData = {
                title: "We are sorry!",
                content: "We could not find results matching your Best Seats selection. Please, try again with a different selection.",
                acceptBtnName: 'OK'
              };
              this.seatManagementService.unselectAllSelectedSeats();
              this.modalsService.openModal(modalData);
            }
          }).catch(err => {
            // Probably the map has no best nodes information
            console.error(err);
            const modalData = {
              title: "ERROR",
              content: "An error occurred while trying to find your Best Seats.",
              acceptBtnName: 'CLOSE'
            };
            this.modalsService.openModal(modalData);
          });
        } else {
          this.selectAccessibleSeats(accessibleSeats, null);
        }

      },
      error: error => {
        console.error(error);
        const modalData = {
          title: "ERROR",
          content: "An Error occurred while trying to get Seat Availability.",
          acceptBtnName: 'CLOSE'
        };
        // If there's a custom api error.
        if (error.error.hasOwnProperty('code')) {
          modalData.content = error.error.message;
        };
        this.modalsService.openModal(modalData);
      }
    });
    // console.log('Input: ', input);
  }

  // Called from load handler, when the section map is loaded (because it can't select seats before)
  selectBestXSeats() {
    this.seatManagementService.selectAvailableSeats(this.bestSeatsIds);
  }

}

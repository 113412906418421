import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from "rxjs";
import { UtilitiesService } from '../../services';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnDestroy {
  
  isMobile: boolean;

  timerObservable = new Subject<string>();
  timer;

  transactionTimerLimit = 10; // todo hardcoded
  isTimerOver = false;
  interval;
  _createdTime: string;
  @Output() transactionExpired = new EventEmitter<boolean>();

  @Input() set createdTime(value) {
    if (!value) {
      return;
    }
    this._createdTime = value;
    this.timerObservable.subscribe(
      timer => {
        this.timer = timer;
      }
    );
    this.setTimer();
    this.setTimerInterval();
  }

  get createdTime() {
    return this._createdTime
  }

  get getTimer() {
    return this.timer;
  }

  constructor(private utilitiesService: UtilitiesService) {
  }

  ngOnInit(): void {
    // Device detection
    this.isMobile = this.utilitiesService.isMobile;
  }

  ngOnDestroy() {
    this.timerObservable.unsubscribe();
    clearInterval(this.interval);
  }

  /**
   * Sets the timer with an interval of 1 seg (to do a chronometer)
   */
  setTimerInterval(): void {
    this.interval = setInterval(() => {
      this.setTimer();
    }, 1000);
  }

  setTimer(): void {
    // Gets the appointment date
    const date = this.createdTime;
    // Gets the timestamp of appointment date
    const createdTime = new Date(date).getTime();
    // Gets the current date
    const now = new Date().getTime();
    // Gets the difference between current date and appointment date
    let diff = ((this.transactionTimerLimit * 60000) - (now - createdTime)) / 1000;
    if (diff < 0) {
      diff = 0;
      // Control so it pops one time
      if (!this.isTimerOver) {
        this.transactionExpiredModal();
        this.isTimerOver = true;
      }
    }
    // Parses the difference result
    const timeString = this.time2string(diff);
    // Updates the subject with the new value
    this.timerObservable.next(timeString);
  }

  transactionExpiredModal() {
    this.transactionExpired.emit(true);
  }

  time2string(timeStamp) {
    // Days
    const days = Math.floor(timeStamp / 86400);
    timeStamp -= days * 86400;
    // Hours
    const hoursNum = Math.floor(timeStamp / 3600) % 24;
    const hours = (hoursNum < 10 ? '0' : '') + hoursNum;
    timeStamp -= hoursNum * 3600;
    // Minutes
    const minutesNum = Math.floor(timeStamp / 60) % 60;
    const minutes = (minutesNum < 10 ? '0' : '') + minutesNum;
    timeStamp -= minutesNum * 60;
    // Seconds
    const secondsNum = Math.floor(timeStamp % 60);
    const seconds = (secondsNum < 10 ? '0' : '') + secondsNum;
    return minutes + ':' + seconds;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { OktaIntegrationService } from "../../services/okta-integration.service";

@Component({
  selector: 'app-okta-container',
  templateUrl: './okta-container.component.html',
  styleUrls: ['./okta-container.component.scss']
})
export class OktaContainerComponent implements OnInit {

  @Input() text: string;
  @Output() patronEmmiter: EventEmitter<any> = new EventEmitter();
  @Input() parentObservable?: Observable<string>;
  patron: any;
  isAuthenticated$!: Observable<boolean>;

  constructor(private _oktaService: OktaIntegrationService) { }

  async ngOnInit(): Promise<void> {
    // TODO: Check template
    this.isAuthenticated$ = this._oktaService.getIsAuthenticated()
    if (this.parentObservable) {
      this.parentObservable.subscribe({ next: (data: string) => this.receiveEventFromParent(data) });
    }
    if (firstValueFrom(this.isAuthenticated$)) {
      this.patron = await this._oktaService.getUserInfo();
      this.patronEmmiter.emit(this.patron);
    }
  }

  signInRedirect(): void {
    let origin = window.location.href
    this._oktaService.signInWithRedirect(origin, 'okta/redirect');
  }

  signOut(): void {
    let origin = window.location.origin
    this._oktaService.signOut(origin);
  }

  receiveEventFromParent(data: string): void {
    // with this event the component deletes all client information and clears the local store, as if the client had never pressed the butto
    if (data === 'REMOVE_PATRON_INFO') {
      localStorage.clear();
      this.patron = null;
    }
  }

}

import { DVMViewer3dInitializerOptions, DVMViewerInitializerOptions, loadModule, MapViewer, Viewer3d } from '@3ddv/dvm-internal';
import { Injectable, NgZone, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalsService } from 'src/app/shared/modals/modals.service';

@Injectable({
  providedIn: 'root'
})
export class DvmService {
  // html placement for popovers
  popoverPlacement: ViewContainerRef;
  isTopview: boolean = false;

  viewer: MapViewer;
  viewerSubject: Subject<any> = new Subject<any>();
  viewer3d: Viewer3d;
  is3dOpen = false;
  viewerMini: MapViewer;
  viewerMiniSubject: Subject<any> = new Subject<any>();
  isSectionMap: boolean;
  seatmapId: string;

  inputOptions: DVMViewerInitializerOptions = {
    container: 'map-container', // Container where the viewer will be appended
    styles_by_groups: true,
    plugins: ['best_nodes', 'selection_area']
  };
  inputOptions3d: DVMViewer3dInitializerOptions = {
    container: 'viewer3d-container', // Container where the viewer will be appended
    version: 'stable',
  };
  inputOptionsMiniMap: DVMViewerInitializerOptions = {
    container: 'mini-map-container',
    version: 'development',
    // plugins: ['best_nodes', 'selection_area']
  };

  loadOptions = {
    venue_id: 'nam-us-00096-chicagocubs', // Venue to be loaded. 3ddigitalvenue will provide these IDs
    map_id: 'main_venuemap'  // Map id. "main" by default.
  }
  loadOptions3d = {
    venue_id: 'nam-us-00096-chicagocubs', // Venue to be loaded. 3ddigitalvenue will provide these IDs
  }
  loadOptionsMiniMap = {
    venue_id: 'nam-us-00096-chicagocubs',
    map_id: 'blockmap_split_minimap'
  }
  // todo GA change for bleacher
  // TEST
  /*GA_sectors = ["S_102", "S_103"]
  bleacherSection = "S_102"*/
  // Production

  GA_sectors = ["S_501", "S_502", "S_503", "S_504", "S_505", "S_506", "S_507", "S_511", "S_512", "S_513", "S_514", "S_515",
    "S_536", "S_538", "S_537"];
  GA_ada = ["S_CenterFieldADA", "S_LeftFieldADA", "S_RightFieldADA", "S_LeftFieldADA1", "S_LeftFieldADA2"];
  bleacherSection = "S_Bleacher"
  bleacherAdaSection = "S_BLCHADA"
  /*
*  MAP BY LAYERS
*  layer with id 'section_tier1' at level 0
*  layer with id 'seat_tier1' at level 1
*  layer with id 'section_tier2' at level 2
*  layer with id 'seat_tier2' at level 3
*
*  viewer.layers.flags.automatic_control_level = false;
*
*  viewer.layers.setLayerLevel(2);
*/

  styles: any;

  constructor(private ngZone: NgZone,
              private modalsService: ModalsService) {
    (window as any).loadMap = this.loadMap.bind(this);
  }


  initializeDVM() {
    //* LOADING MODULE
    // This method will return a Promise that will resolve returning the initialized instance of the module.
    /*
    Loads a module asynchronously
    @param {string} module_name - Name of the module to be loaded
    @param {InputOptions} [input] - Object with the necessary properties to
    initialize the module. This input is different for each module.
    */
    // this.ngZone.runOutsideAngular(() => {
    loadModule('map_viewer', this.inputOptions)
      .then(viewer => {
        // console.log("VIEWER RECEIVED BY THE CLIENT", viewer);
        // To be able to use viewer on browser Console
        (window as any).viewer = viewer;

        // console.log('Map Id: ', viewer.getMapId());
        //TODO Provisional here. This flag lines may be inside loadMap method, when it could be just one function for all loading maps.
        viewer.flags.automatic_selection = false;
        viewer.flags.automatic_hover = false;
        viewer.selection_area.enabled = false;
        // Selection
        viewer.max_selection = 999;

        // viewer.subscribe('zooming', () => {
        //   if (viewer.getMapId() === 'main_tiers') {
        //     const currentLevel = viewer.layers.getLayerLevel();
        //     const currentZoom = viewer.scaling_factor;
        //     if (currentZoom >= 5) {
        //       if (currentLevel%2 === 0) {
        //         viewer.layers.setLayerLevel(currentLevel + 1);
        //       }
        //     } else {
        //       if (currentLevel%2 === 1) {
        //         viewer.layers.setLayerLevel(currentLevel - 1);
        //       }
        //     }
        //   }
        // });

        this.viewer = viewer;
        this.viewerSubject.next(viewer);

        //* LOADING MAP
        this.loadMap();
      })
      .catch(err => {
        console.error(err);
        const modalData = {
          title: "ERROR",
          content: "An Error occurred while trying to receive the Viewer for Map.",
          // closeBtnName: 'CLOSE',
          acceptBtnName: 'CLOSE',
          // acceptFunction: () => {this.goTo('checkout')}
        };
        this.modalsService.openModal(modalData);
      });
    // });
  }

  initializeDVMMiniMap() {
    // console.log(this.inputOptionsMiniMap);

    loadModule('map_viewer', this.inputOptionsMiniMap)
      .then(viewerMini => {
        // console.log("MINI VIEWER RECEIVED BY THE CLIENT", viewerMini);
        // To be able to use viewer on browser Console
        (window as any).viewerMini = viewerMini;

        viewerMini.flags.fixed_aspect_ratio = false;
        viewerMini.flags.automatic_selection = false;
        viewerMini.flags.automatic_hover = false;
        viewerMini.flags.zooming = false;
        viewerMini.flags.panning = false;

        this.viewerMini = viewerMini;
        this.viewerMiniSubject.next(viewerMini);

        //* LOADING MINI MAP
        this.loadMiniMap();
      })
      .catch(err => {
        console.error(err);
        const modalData = {
          title: "ERROR",
          content: "An Error occurred while trying to receive the Viewer for Mini Map.",
          // closeBtnName: 'CLOSE',
          acceptBtnName: 'CLOSE',
          // acceptFunction: () => {this.goTo('checkout')}
        };
        this.modalsService.openModal(modalData);
      });
  }

  loadMap(mapId?: string) {
    const loadOptions = JSON.parse(JSON.stringify(this.loadOptions));
    this.isSectionMap = false;
    // console.log('mapId: ', mapId);
    if (mapId) {
      this.viewer.selection_area.enabled = true;
      loadOptions.map_id = mapId;
      this.isSectionMap = true;
      this.seatmapId = mapId;
      // this.loadMiniMap();
    }
    // console.log('loadOptions: ', loadOptions);
    // Load the map
    return this.viewer.loadMap(loadOptions).then(() => {
      // throw {name: 'Error!', message: "There's been an error."};
      // Successfully loaded
      console.log('LOADED MAP!');
      if (this.viewer.getMapId() === this.loadOptions.map_id) {
        this.isTopview = true;
      } else {
        this.isTopview = false;
      }

      // console.log('IS TOPVIEW', this.isTopview);


      this.viewer.flags.fixed_aspect_ratio = false;
      this.viewer.flags.max_zoom_on_first_limit = true;
    })
      .catch((err: any) => {
        // Error while loading
        console.error(err);
        const modalData = {
          title: "ERROR",
          content: "An Error occurred while trying to load the Venue Map.",
          // closeBtnName: 'CLOSE',
          acceptBtnName: 'CLOSE',
          // acceptFunction: () => {this.goTo('checkout')}
        };
        this.modalsService.openModal(modalData);
      });
  }

  loadMiniMap() {
    // console.log('loadOptionsMiniMap: ', this.loadOptionsMiniMap);
    // Load the map
    this.viewerMini.loadMap(this.loadOptionsMiniMap).then(() => {
      // Successfully loaded
      console.log('LOADED MINI MAP!');
    })
      .catch((err: any) => {
        // Error while loading
        console.error(err);
        const modalData = {
          title: "ERROR: Mini Map couldn't load",
          content: "An Error occurred while trying to load the Mini Map.",
          // closeBtnName: 'CLOSE',
          acceptBtnName: 'CLOSE',
          // acceptFunction: () => {this.goTo('checkout')}
        };
        this.modalsService.openModal(modalData);
      });
  }

  changeSectionStyles(tags?: string) {
    this.styles = this.viewer.getStyles();
    // console.log('Section Map Styles: ', this.styles);
    this.styles[0].section.available.normal.none.fillStyle = '#1995FF';  // Blue light
    this.styles[0].section.available.normal.none.strokeStyle = '#005A9C';  // Blue
    this.styles[0].section.available.hover.none.fillStyle = '#005A9C';  // Blue
    this.styles[0].section.available.hover.none.strokeStyle = '#FFFFFF';  // White

    this.styles[0].section.selected.normal.none.fillStyle = '#7A40B9';  // Purple
    this.styles[0].section.selected.normal.none.strokeStyle = '#7A40B9';  // Purple
    this.styles[0].section.selected.hover.none.strokeStyle = '#FFFFFF';  // White

    let group1 = JSON.parse(JSON.stringify(this.styles[0].section.available.normal.none));
    group1.fillStyle = '#FF4D4D';  // Red (Def selected color)
    group1.strokeStyle = '#FF4D4D';  // Red (Def selected color)
    this.styles[0].section.available.normal['group1'] = group1;

    // if (tags === 'withTags') {
    //   console.log('WITH TAGS');
    //   this.styles[0].section.available.normal.myseats.fillStyle = '#FF4D4D';  // Red (Def selected color)
    //   this.styles[0].section.available.normal.myseats.strokeStyle = '#801A1A';  // Red dark (Def selected color)
    // }

    let success = this.viewer.setStyles(this.styles);
    // console.log('Section Styles Setted? ', success);
    // console.log('New Section Map Styles: ', this.styles);
  }

  changeSeatStyles() {
    this.styles = this.viewer.getStyles();
    // console.log('Seat Map Styles: ', this.styles);
    this.styles[0].seat.available.normal.none.fillStyle = '#1995FF';  // Blue light
    this.styles[0].seat.available.normal.disabled.fillStyle = '#1995FF';  // Blue light
    this.styles[0].seat.available.normal.none.strokeStyle = '#005A9C';  // Blue
    this.styles[0].seat.available.normal.disabled.strokeStyle = '#005A9C';  // Blue

    this.styles[0].seat.available.normal.pending.fillStyle = 'orange';
    this.styles[0].seat.available.normal.pending.strokeStyle = 'orange';

    // unavailable styles
    this.styles[0].seat.unavailable.normal = { none: {
      fillStyle: '#d6d6d6',
      stroke: '#d6d6d6',
      lineWitdh: 0.05,
      opacity: 0.7
    }}

    // this.styles[0].seat.available.hover.none.fillStyle = '#005A9C';  // Blue
    // this.styles[0].seat.available.hover.none.strokeStyle = '#173482';  // Blue dark

    // this.styles[0].seat.available.hover.disabled.fillStyle = '#005A9C';  // Blue
    // this.styles[0].seat.available.hover.disabled2.fillStyle = '#005A9C';  // Blue
    // this.styles[0].seat.available.hover.pending.fillStyle = '#005A9C';  // Blue
    // this.styles[0].seat.available.hover.restricted.fillStyle = '#005A9C';  // Blue
    // this.styles[0].seat.available.hover['social-distancing'].fillStyle = '#005A9C';  // Blue
    // this.styles[0].seat.available.hover['visibility-reduced'].fillStyle = '#005A9C';  // Blue

    let success = this.viewer.setStyles(this.styles);
    // console.log('Seat Styles Setted? ', success);
    // console.log('New Seat Map Styles: ', this.styles);
  }

  load3dView(viewId) {
    const finalize = () => {
      const loadOptions = JSON.parse(JSON.stringify(this.loadOptions3d));
      loadOptions.view_id = viewId;
      return this.viewer3d.loadView3d(loadOptions).then(() => {
        console.log('3d View open.');
        this.viewer3d.flags.fixed_aspect_ratio = false;
        this.is3dOpen = true;
        this.viewer3d.interface.setLabelPosition('bottomright');
      });
    }
    if (!this.viewer3d) {
      return loadModule('3d_viewer', this.inputOptions3d)
        .then(viewer3d => {
          (window as any).viewer3d = viewer3d;
          this.viewer3d = viewer3d;
          return finalize();
        });
    } else {
      return finalize();
    }
  }

  close3dView() {
    if (this.viewer3d) {
      // this.viewer3d.close();
      this.is3dOpen = false;
    }
  }

}

import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { ConnectionService } from '../services/connection.service';

@Injectable({
  providedIn: 'root'
})
export class OpponentsResolver implements Resolve<boolean> {
  constructor(private connection: ConnectionService){}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.connection.getOpponents().pipe(
      map((list) => list),
      catchError((error) => {
        console.log(error);
        return of(false)
      })
    )
  }
}

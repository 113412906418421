import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { catchError, filter, map, Observable, of, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AvailabilityService } from '../services';
import { ConnectionService } from '../services/connection.service';

@Injectable({
  providedIn: 'root',
})
export class EventDataResolver
  // implements Resolve<SiteModel | { flow: null; error: any }>
  implements Resolve<any>
{
  constructor(
    private http: HttpClient,
    private connectionService: ConnectionService,
    private activatedRoute: ActivatedRoute,
    private availabilityService: AvailabilityService
    // private sbApiService: SbApiService,
    // private toastr: ToastrService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // console.log(route);
    // let eventId = route.params['eventId'];
    // let eventId = route.queryParams['event'];
    let ev = route.queryParamMap.get('event');
    let eventId = parseInt(ev, 10);
    // console.log(eventId);
    this.connectionService.eventId = eventId;

    return this.connectionService.getEventById(eventId).pipe(
      map((site) => {
        this.connectionService.eventId = eventId;
        return site
      }),
      catchError((error) => {
        console.log(error);
        // this.toastr.error('', 'Se ha producido un error');
        return of({ event: null, error: error });
      })
    );

  // private getSite(eventId: number): Observable<any> {
  //   return this.http.get<any>(
  //     `${this.connectionService.apiURL}/events/${eventId}`
  //   );
  // }
  // private getSite(id: number): Observable<SiteModel> {
  //   return this.http.get<SiteModel>(
  //     `${environment.apiSiteUrl}${this.apiRoot}/sites/${id}`
  //   );

  }

}

import { Component, OnInit } from '@angular/core';
import { LoadingBarService } from "@ngx-loading-bar/core";
import { UtilitiesService } from '../shared/services';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  isMobile: boolean;

  constructor(private loadingBar: LoadingBarService,
              private utilitiesService: UtilitiesService) {
    const loader = this.loadingBar.useRef();
    //loader.start();
  }

  ngOnInit(): void {
    // Device detection
    this.isMobile = this.utilitiesService.isMobile;
  }

}

<div [ngClass]="isMobile? 'w-screen p-4': 'p-6'">
  <!-- Pulse animation -->
  <div class="absolute z-20 rounded-full" [ngClass]="[acquiredUpsell? 'bg-green': 'bg-red', reachingUpsell || acquiredUpsell? 'animate-ping': '', isMobile? 'w-5 h-5 top-6 right-6': 'w-8 h-8 top-8 right-8']"></div>
  
  <!-- Switcher btn -->
  <button (click)="toggleGiftsBox()" class="absolute z-20 flex justify-center items-center rounded-full" [ngClass]="[acquiredUpsell? 'bg-green': 'bg-red', isMobile? 'w-9 h-9 top-4 right-4': 'w-12 h-12 top-6 right-6']">
    <img src="/assets/img/box-gift-icon.svg" alt="Box gift icon" [ngClass]="{'w-5 h-5': isMobile}">
  </button>
  
  <!-- Badge total -->
  <div *ngIf="acquiredUpsells?.length && !openUpsellsBox" class="mt-1 absolute z-30 flex justify-center items-center bg-white rounded-full" [ngClass]="isMobile? 'w-5 h-5 top-10 right-2': 'w-6 h-6 top-14 right-4'">
    <h5 [ngClass]="{'text-xs': isMobile}">{{ acquiredUpsells.length }}</h5>
  </div>

  <div class="p-1 relative flex justify-end overflow-hidden" [ngClass]="[reachingUpsell || acquiredUpsell? 'w-160 pointer-events-auto' : 'w-0 pointer-events-none', isMobile? 'w-auto h-9 mr-2': 'h-12 mr-3']">
    <!-- Temporal line Message -->
    <div class="line-msg absolute" [ngClass]="[reachingUpsell? 'line-active': '', isMobile? 'w-auto h-7 pl-4 pr-10': 'w-max h-10 pl-6 pr-14']">
      <p id="reaching-msg" [ngClass]="{'text-xxs leading-3': isMobile}">
        Purchase
        <span class="font-bold"> {{ reachingUpsell?.goal }} tickets </span>and receive
        <span class="font-bold"> {{ reachingUpsell?.name }}!</span>
      </p>
    </div>
    <div class="line-msg absolute" [ngClass]="[acquiredUpsell? 'line-active': '', isMobile? 'w-auto h-7 pl-4 pr-10': 'w-max h-10 pl-6 pr-14']">
      <p id="acquired-msg" [ngClass]="{'text-xxs leading-3': isMobile}">
        Congratulations! You unlocked the
        <span class="font-bold"> {{ acquiredUpsell?.name }} </span>by purchasing
        <span class="font-bold"> {{ acquiredUpsell?.goal }} tickets</span>
      </p>
    </div>
  </div>

  <!-- Upsells wrapper -->
  <div *ngIf="openUpsellsBox" class="w-64 px-6 pb-4 m-1 absolute text-blue bg-white rounded-lg shadow-lg" [ngClass]="isMobile? 'pt-9 top-6 right-6 ': 'pt-12 top-8 right-8'">
    <!-- Group Benefits List -->
    <h4 class="mb-2">Group Benefits</h4>
    <ul>
      <li *ngFor="let upsell of acquiredUpsells" class="mb-2 flex">
        <img class="mr-2" src="/assets/img/check-circle-fill-icon.svg" alt="Check circle fill icon">
        <p>You unlocked the
          <span class="font-bold"> {{ upsell.name }} </span>by purchasing
          <span class="font-bold"> {{ upsell.goal }} tickets.</span>
        </p>
      </li>
    </ul>
    <hr [ngClass]="isMobile? 'my-3': 'my-4'">
    <!-- Next Gifts List -->
    <!-- <h4 class="mb-2">Next Gifts</h4> -->
    <ul>
      <li *ngFor="let upsell of availableUpsells" class="mb-2 flex">
        <img class="mr-2" src="/assets/img/check-circle-empty-icon.svg" alt="Check circle empty icon">
        <p>Purchase
          <span class="font-bold"> {{ upsell.goal }} tickets </span>and receive
          <span class="font-bold"> {{ upsell.name }}.</span>
        </p>
      </li>
    </ul>
  </div>

</div>
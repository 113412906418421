import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OktaIntegrationComponent } from './okta-integration.component';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { environment } from 'src/environments/environment';
import OktaAuth from '@okta/okta-auth-js';
import { OktaContainerComponent } from './components';
import { RedirectPageComponent } from './components/redirect-page/redirect-page.component';

const oktaAuth = new OktaAuth({
  issuer: `${environment.oktaData.issuer}`,
  clientId: `${environment.oktaData.clientId}`,
  redirectUri: window.location.origin + '/login',
})

@NgModule({
  declarations: [
    OktaIntegrationComponent,
    OktaContainerComponent,
    RedirectPageComponent
  ],
  imports: [
    CommonModule,
    OktaAuthModule,
  ],
  exports: [
    OktaContainerComponent
  ],
  providers: [
    { provide: OKTA_CONFIG, useValue: { oktaAuth } }
  ]
})
export class OktaIntegrationModule { }

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, Event, NavigationEnd, Router, ActivatedRouteSnapshot } from '@angular/router';
import { ConnectionService } from "../../shared/services/connection.service";
import { SaleEvent } from "../../shared/models";
import { DvmService } from 'src/app/digital-venue/services/dvm.service';
import { UtilitiesService } from 'src/app/shared/services';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { TutorialService } from 'src/app/shared/services/tutorial.service';
import { IsolatedSeatsService } from 'src/app/digital-venue/services/isolated-seats.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  isMobile: boolean;

  landing: boolean;

  transactionId: string;
  actualPage: string;

  showLegend: boolean;
  backBtn: 'payment' | 'summary';

  // Legend
  // ownedColor: string;
  availableColor: string;
  mySeatsColor: string;
  selectedColor: string;
  isolatedColor: string;
  event: SaleEvent;

  get isTopview() {
    return this.dvmService.isTopview;
  }
  get isSectionMap() {
    return this.dvmService.isSectionMap;
  }
  get is3dOpen() {
    return this.dvmService.is3dOpen;
  }

  get isIsolatedActive() {
    return this.isolatedSeatsService.isolatedSeatsActive;
  }

  constructor(public router: Router,
              private activeRouter: ActivatedRoute,
              private connectionService: ConnectionService,
              private dvmService: DvmService,
              private modalsService: ModalsService,
              private utilitiesService: UtilitiesService,
              private isolatedSeatsService: IsolatedSeatsService,
              private tutorialService: TutorialService) {
    // this.ownedColor = 'purple';
    this.availableColor = 'blue-light';
    this.mySeatsColor = 'red';
    this.selectedColor = 'purple';
    this.isolatedColor = 'orange';
  }

  ngOnInit(): void {
    // Device detection
    this.isMobile = this.utilitiesService.isMobile;

    this.router.events.subscribe((event: Event) => {
      // console.log('Event', event, 'SNAPSHOT', this.activatedRoute.snapshot);
      if (event instanceof NavigationEnd) {
        // console.log('Event Navigation End', event);
        // console.log(this.activatedRoute.snapshot);
        this.showLegend = event.url.includes('seat-selection');
        this.landing = event.url.includes('events')
      }
      if (event instanceof ActivationEnd && event.snapshot.url.length) {
        // console.log(event.snapshot);
        this.transactionId = event.snapshot.params['id'];
        // console.log('Trans Id: ', this.transactionId);


        this.actualPage = event.snapshot.url[0].path;
        switch (this.actualPage) {
          case 'payment':
            this.backBtn = 'payment';
            break;
          case 'summary':
            this.backBtn = 'summary';
            break;
          default:
            this.backBtn = null;
            break;
        }
      }
    });
    this.connectionService.getEvent().subscribe(data=> this.event=data);
  }

  goBack(page: string) {
    // console.log('trans Id: ', this.transactionId);
    let backPage = 'checkout';
    if (page === 'summary') {
      backPage = 'payment';
    }
    this.router.navigate([`${backPage}/${this.transactionId}`], { queryParams: { token: this.connectionService.token } });
  }

  goBackToTopview() {
    if (this.dvmService.is3dOpen) {
      // Close 3D
      this.dvmService.close3dView();
    } else {
      this.dvmService.loadMap();
    }
  }

  openLegend() {
    let dynamicLegend = `<span class="mx-2 flex items-center">
                      <span class="w-2 h-2 mx-2 bg-${this.isolatedColor} rounded-full"></span>
                      <p>Isolated</p>
                    </span>`;
    if(this.isTopview){
      dynamicLegend = `<span class="mx-2 flex items-center">
                    <span class="w-2 h-2 mx-2 bg-${this.selectedColor} rounded-full"></span>
                    <p>Selected</p>
                  </span>`;
    }

    const modalData = {
      title: "Location Legend",
      content: `<span id="legend">
                  <span class="mb-4 flex justify-center">
                    <span class="mx-2 flex items-center">
                      <span class="w-2 h-2 mx-2 bg-${this.availableColor} rounded-full"></span>
                      <p>Available</p>
                    </span>
                    <span class="mx-2 flex items-center">
                      <span class="w-2 h-2 mx-2 bg-${this.mySeatsColor} rounded-full"></span>
                      <p>My seats</p>
                    </span>
                  </span>
                  <span class="flex justify-center">
                    ${dynamicLegend}
                  </span>
                </span>`,
      acceptBtnName: 'CLOSE'
    };
    this.modalsService.openModal(modalData);
  }

  // Tutorial
  openTutorialQuestion() {
    this.tutorialService.openTutorialInit();
  }


  returnActualRoute() {
    console.log(this.activeRouter.pathFromRoot[1].snapshot.url[0].path);
  }

}
